import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_CUTI({ commit }, { page, order, search, sortBy }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/cuti/table`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        const { cuti } = data.data

        return new Promise((resolve, reject) => {
          resolve(cuti)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_CUTI({ commit }, payload) {
      let isSuccess = false
      try {
        const { dataCuti } = payload
        await axios(`${config.apiUrl}/api/cuti`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataCuti,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async EDIT_CUTI({ commit }, payload) {
      let isSuccess = false
      try {
        const { id, dataCuti } = payload
        await axios(`${config.apiUrl}/api/cuti/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataCuti,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_CUTI({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/cuti/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_PENGAJUAN_CUTI({ commit }, { page, order, search, sortBy }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/cuti/pengajuan_cuti`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        const { pengajuanCuti } = data.data

        return new Promise((resolve, reject) => {
          resolve(pengajuanCuti)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async APPROVE_PENGAJUAN_CUTI({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/cuti/pengajuan_cuti/terima/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async REJECT_PENGAJUAN_CUTI({ commit }, { id, dataPengajuanCuti }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/cuti/pengajuan_cuti/tolak/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataPengajuanCuti,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
  },
}
