export default {
  // apiUrl: 'https://edutech.albayan.sch.id:3000',
  // apiUrl: 'https://demohr.hasanteknologi.id:4000',
  apiUrl: 'http://localhost:3000',
  school: {
    // name: 'Edutech Albayan Islamic School',
    // name2: 'SMP Albayan Islamic School',
    // name3: 'LEMBAGA PENDIDIKAN ISLAM AL-BAYAN',
    name: 'Hasan Educational Technology',
    name2: 'Hasan Educational Technology School',
    name3: 'Hasan Educational Technology',
    logo: 'Logo-Hasan.png',
    // logo: 'Logo-Albayan-Islamic-School.png',
    logoCompress: 'logo_2.jpeg',
    type: 'SMP',
    religion: 'Islam',
    address: 'Jl. Basoka Raya, Kavling Hankam Blok R No. 6 Joglo,',
    city: ' Jakarta Barat, DKI Jakarta',
    number: 'Telp. (021) 584 0929 Fax. (021) 584 0929 584 8854',
  },
  metadata: {
    pegawai: {
      nik: 123,
      nama: 'Ade Badar',
      jabatan_pegawais: [
        {
          jabatan: {
            nama: 'Kepala Sekolah',
            jabatan_tunjangan: {
              tunjangan_fungsional: 45,
              tunjangan_jabatan: 45,
              tunjangan_lain: 0,
              tunjangan_operasional: 500000,
              tunjangan_transportasi: 45000,
              tunjangan_makan: 45000,
            },
          },
        },
      ],
      unit: {
        nama: 'SMP',
      },
      golongan: {
        nama: '3C',
        gaji_pokok: 1700000,
      },
      marital_tunjangan: {
        status_marital: 'M2',
        laki_laki: 6,
        perempuan: 3,
        tunjangan_keluarga: 12,
        tunjangan_kepala_keluarga: 10,
      },
    },
    rekap_absensi: {
      periode: {
        tanggal_awal: '2021-02-26',
        tanggal_akhir: '2021-03-25',
      },
      rincian_gaji: {
        tanggal_pembayaran_gaji: '2021-03-17 12:00',
        total_penghasilan: 0,
        total_pengurangan: 0,
        total_gaji: 0,
      },
      sakit: 0,
      izin: 0,
      alfa: 0,
      cuti: 0,
      libur: 1,
      terlambat: 0,
      pulang_lebih_awal: 0,
      hkn: 16,
      jhk: 15,
      thk: 0,
    },
  },
}
