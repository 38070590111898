import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment'
import router from '@/router'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    slots: [],
    sesis: [],
    kelasSesis: [],
    currentSlot: {},
    currentSlotStatus: '',
    currentDay: new Date(),
    semesterFirstDay: '',
    // semesterFirstDay: moment('4 January 2021', 'DD MMMM YYYY'),
    // semesterFirstDay: '04 January 2021',
    biweeklyTopic: [],
    sesiGradings: {},
    assignmentInProgress: {
      count: [],
      rows: [],
    },
    modelTipe: 'A',
    allAssignmentInProgress: [],
    menuSlots: [],
    overviewSlot: {},
    overviewAssignment: {},
    overviewAips: [],
    aapAips: [],
    overviewClassAipPresence: {},
    listAipPresencesMurids: [],
    assignmentColumns: [],
    dataOfTablePlanning: [],
    dataOfTableAssignmentGrading: [],
    dataOfTableAP: [],
    semesterProgress: {},
    idNextSession: null,
    fileMaterials: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_TAHUN_AJARAN_ACTIVE({ commit, dispatch, state }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/tahun_ajaran/active`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        const tanggalMoment = moment(data.data.tanggal_awal)
        // state.semesterFirstDay = new Date(tanggalMoment)
        // console.log(state.semesterFirstDay)
        return new Promise((resolve, reject) => {
          resolve(tanggalMoment)
        })
      } catch (error) {
        console.log(error)
      }
    },
    FETCH_SLOT_TEACHER({ commit, dispatch, state }, { idGuru }) {
      commit('SET_STATE', {
        isLoading: true,
      }, { root: true })
      axios(`${config.apiUrl}/api/slot/schedule`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
          tipe: state.modelTipe,
        },
      })
        .then(({ data: { data } }) => {
          // console.log(data)
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          commit('SET_STATE', {
            slots: data,
          })
        })
        .catch(err => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          console.log(err)
        })
    },
    async FETCH_WEEKS({ commit, dispatch, state }) {
      try {
        await axios(`${config.apiUrl}/api/week/by-tahun-ajaran`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        // console.log('WEEKS', data)
      } catch (error) {
        console.log(error)
      }
    },
    FETCH_SLOT_STUDENT({ commit, dispatch, state }, { idKelas }) {
      commit('SET_STATE', {
        isLoading: true,
      }, { root: true })
      axios(`${config.apiUrl}/api/slot/studentSchedule`, {
        method: 'get',
        params: {
          id_kelas: idKelas,
          tipe: state.modelTipe,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then(({ data: { data } }) => {
          // console.log(data)
          setTimeout(() => {
            commit('SET_STATE', {
              isLoading: false,
            }, { root: true })
          }, 1000)
          commit('SET_STATE', {
            slots: data,
          })
        })
        .catch(err => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          console.log(err)
        })
    },
    async FETCH_CURRENT_SLOT({ commit, dispatch, state }, { idSession }) {
      try {
        commit('SET_STATE', {
          isLoading: true,
        }, { root: true })
        let zoomSetting = false
        const { data: { data, idNextSession } } = await axios(`${config.apiUrl}/api/sesi/${idSession}`, {
          // await axios(`http://localhost:3000/api/sesi/${idSession}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })

        // console.log(data)
        commit('SET_STATE', {
          currentSlot: {},
        })
        commit('SET_STATE', {
          isLoading: false,
        }, { root: true })
        commit('SET_STATE', {
          currentSlot: data,
          idNextSession,
        })
        zoomSetting = data.zoom_setting

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(zoomSetting)
          }, 1000)
        })
      } catch (err) {
        commit('SET_STATE', {
          isLoading: false,
        }, { root: true })
        if (err.response.data.status === 'NOT_AUTHORIZED') {
          router.push({ name: '404' })
        }
        console.log(err.response)
      }
      // console.log(idSession, 'ini di actions store')
    },
    // ! DASHBOARD GURU TODAY CLASS
    FETCH_TODAY_CLASS({ commit, dispatch }, { idGuru }) {
      commit('SET_STATE', {
        isLoading: true,
      }, { root: true })
      axios(`${config.apiUrl}/api/dashboard-guru/kelas`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
        },
      })
        .then(({ data }) => {
          // console.log('masuk')
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          // console.log(data.data)
          commit('SET_STATE', {
            sesis: data.data,
          })
        })
        .catch(err => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          console.log(err)
        })
    },
    // ! DASHBOARD MURID TODAY CLASS
    FETCH_TODAY_CLASS_MURID({ commit, dispatch }, { idKelas }) {
      axios(`${config.apiUrl}/api/dashboard-murid/kelas`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_kelas: idKelas,
        },
      })
        .then(({ data }) => {
          // console.log(data.data.rows)
          commit('SET_STATE', {
            kelasSesis: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    // ! BIWEEKLY PLANNING GURU
    FETCH_BIWEEKLY_TOPIC({ commit, dispatch, state }, { idGuru }) {
      commit('SET_STATE', {
        isLoading: true,
      }, { root: true })
      axios(`${config.apiUrl}/api/topik/planning`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
        },
      })
        .then(({ data: { data } }) => {
          // commit('SET_STATE', {
          //   isLoading: false,
          // }, { root: true })
          commit('SET_STATE', {
            biweeklyTopic: data,
          })
        })
        .catch(err => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          console.log(err)
        })
    },
    // ! DASHBOARD GURU AIP
    FETCH_ASSIGNMENT_IN_PROGRESS({ commit, dispatch, state }, { idGuru }) {
      commit('SET_STATE', {
        isLoading: true,
      }, { root: true })
      axios(`${config.apiUrl}/api/dashboard-guru/aip`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
        },
      })
        .then(({ data }) => {
          // console.log(data.data)
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          commit('SET_STATE', {
            assignmentInProgress: data.data,
          })
        })
        .catch(err => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          console.log(err)
        })
    },
    // ! DASHBOARD GURU ALLAIP
    FETCH_ALL_ASSIGNMENT_IN_PROGRESS({ commit, dispatch, state }, { idGuru }) {
      commit('SET_STATE', {
        isLoading: true,
      }, { root: true })
      axios(`${config.apiUrl}/api/dashboard-guru/allaip`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
        },
      })
        .then(({ data }) => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          // console.log(data.data.data1[0])
          commit('SET_STATE', {
            allAssignmentInProgress: data.data,
          })
        })
        .catch(err => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          console.log(err)
        })
    },
    // ! DASHBOARD MURID SEMESTER PROGRESS
    FETCH_SEMESTER_PROGRESS({ commit, dispatch, state }) {
      axios(`${config.apiUrl}/api/dashboard-murid/semester-progress`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
      })
        .then(({ data }) => {
          // console.log(data.data)
          commit('SET_STATE', {
            semesterProgress: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    // ! ASSIGNMENT GRADING GURU
    async FETCH_ASSIGNMENT_GRADING({ commit, dispatch, state }, { idSesi }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/slot/assignmentGrading`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_sesi: idSesi,
          },
        })

        const slot = data
        let no = 0
        const tipeAssignment = slot.dataAG.sesis[0].tipe_assignment
        let pgSoals = ''
        let path = ''
        let answer = ''
        const dataTable = slot.dataAG.sesis[0].attendances.map(row => {
          no++
          const submitTime = row.submit_time === null ? 'Waiting' : moment(row.submit_time).format('DD MMM YYYY, HH:mm')
          if (tipeAssignment === 'File Upload') {
            path = row.tugas_filepath
            answer = row.tugas_filename
          } else if (tipeAssignment === 'Essay') {
            path = ''
            answer = row.essay_answer
          } else if (tipeAssignment === 'Multiple Choice') {
            path = ''
            answer = JSON.parse(row.jawaban_murid)
            pgSoals = slot.dataAG.sesis[0].pg_soals
          }
          return {
            key: row.id_murid,
            No: no,
            idAttendance: row.id,
            idMurid: row.id_murid,
            Name: row.murid.nama,
            SubmitStatus: submitTime,
            TipeAssignment: tipeAssignment,
            AssignmentPath: path,
            AssignmentDetail: pgSoals,
            AssignmentAnswer: answer,
            Feedback: row.feedback,
            Grade: row.tugas_nilai,
            Notes: row.notes,
          }
        })

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({ slot, dataTable })
          })
        })
      } catch (err) {
        console.log(err)
        if (err.response.data.status === 'NOT_AUTHORIZED') {
          router.push({ name: '404' })
        }
        console.log(err.response)
      }
    },
    // ! PUT GRADING
    async PUT_GRADING({ commit, dispatch, rootState }, payload) {
      // console.log('masuk', payload.data)
      let isSuccess = false
      await axios(`${config.apiUrl}/api/slot/assignmentGrading`, {
        method: 'put',
        headers: {
          token: localStorage.token,
        },
        data: {
          data: payload.data,
        },
      })
        .then(res => {
          isSuccess = true
          const idSesi = payload.idSesi
          dispatch('FETCH_ASSIGNMENT_GRADING', { idSesi })
        })
        .catch(err => {
          isSuccess = false
          console.log(err)
        })

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(isSuccess)
        }, 1000)
      })
    },
    // ! FILE DETAIL SESI
    FETCH_FILE_MATERIAL({ commit, dispatch, state }, { idTopik }) {
      axios(`${config.apiUrl}/api/file/file-topik`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_topik: idTopik,
        },
      })
        .then(({ data }) => {
          commit('SET_STATE', {
            fileMaterials: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    // ! DELETE FILE MATERIAL
    DELETE_FILE_MATERIAL({ commit, dispatch, rootState }, payload) {
      // console.log('masuk', localStorage.token)
      axios.delete(`${config.apiUrl}/api/file/file-topik/${payload.id}`, {
        headers: {
          token: localStorage.token,
        },
      })
        .then(res => {
          const idTopik = payload.id_topik
          dispatch('FETCH_FILE_MATERIAL', { idTopik })
        })
        .catch(err => {
          console.log(err)
        })
    },
    // ! DELETE FILE ASSIGNMENT MURID
    DELETE_FILE_ASSIGNMENT_MURID({ commit, dispatch, rootState }, payload) {
      // console.log('masuk', localStorage.token)
      axios(`${config.apiUrl}/api/attendance/assignment-murid/${payload.id}`, {
        method: 'put',
        headers: {
          token: localStorage.token,
        },
      })
        .then(res => {
          const idMurid = payload.id_murid
          const idSesi = payload.id_sesi
          dispatch('FETCH_USER_ASSIGNMENT_BY_ID_SESSION', { idMurid, idSesi })
        })
        .catch(err => {
          console.log(err)
        })
    },
    // ! PUT DETAIL SESI
    async PUT_DETAIL_SESI({ commit, dispatch, rootState }, payload) {
      let isSuccess = false
      await axios(`${config.apiUrl}/api/sesi/detail-sesi`, {
        // await axios('http://localhost:3000/api/sesi/detail-sesi', {
        method: 'put',
        headers: {
          token: localStorage.token,
        },
        data: {
          data: payload.data,
        },
      })
        .then(res => {
          const idSession = payload.data[0].id
          isSuccess = true
          return dispatch('FETCH_CURRENT_SLOT', { idSession })
        })
        .catch(err => {
          isSuccess = false
          console.log(err)
        })

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(isSuccess)
        }, 1000)
      })
    },
    // ! MENU SLOT PLANNING & ASSIGNMENT CENTER GURU
    async FETCH_MENU_SLOT_TEACHER({ commit, dispatch, state }, { idGuru }) {
      commit('SET_STATE', {
        isLoading: true,
      }, { root: true })
      await axios(`${config.apiUrl}/api/slot/kelastingkat`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
        },
      })
        .then(({ data }) => {
          // console.log(data.data)
          // commit('SET_STATE', {
          //   isLoading: false,
          // }, { root: true })
          commit('SET_STATE', {
            menuSlots: data.data,
          })
        })
        .catch(err => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          console.log(err.response)
        })

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.menuSlots)
        }, 1000)
      })
    },
    // ! OVERVIEW SLOT PLANNING GURU
    FETCH_OVERVIEW_SLOT_TEACHER({ commit, dispatch, state }, { idGuru, namaMapel, tingkat }) {
      // commit('SET_STATE', {
      //   isLoading: true,
      // }, { root: true })
      axios(`${config.apiUrl}/api/sesi/planning/overview`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
          nama_mapel: namaMapel,
          tingkat: tingkat,
        },
      })
        .then(({ data }) => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          let no = 0
          let keyC = 0
          const dataTable = data.data.data.map(row => {
            const updatedAt = moment(row.updatedAt).format('DD MMM YYYY, HH:mm')
            let duration = moment('00:00', 'HH:mm').format('HH:mm')
            const date = []
            row.sesis.forEach(sesiRow => {
              duration = moment(duration, 'HH:mm').add(sesiRow.slot.model_jadwal.durasi, 'minutes').format('HH:mm')
              date.push(moment(sesiRow.tanggal_sesi))
            })
            const minDate = moment.min(date)
            const maxDate = moment.max(date)
            no++
            const dataChildTable = row.sesis.map(rowChildTable => {
              keyC++
              const tglSesi = moment(rowChildTable.tanggal_sesi).format('DD MMMM YYYY')
              let statusLive = ''
              if (rowChildTable.zoom_setting) {
                statusLive = 'Live'
              } else {
                statusLive = ''
              }
              return {
                key: 'C' + keyC,
                Class: rowChildTable.slot.kela.tingkat + '-' + rowChildTable.slot.kela.simbol,
                Date: tglSesi + ', ' + rowChildTable.slot.model_jadwal.jam_mulai,
                // Status: 'Class Done',
                Duration: '00:' + rowChildTable.slot.model_jadwal.durasi,
                Live: statusLive,
                Assign: rowChildTable.tipe_assignment,
              }
            })
            return {
              key: 'D' + no,
              // No: no,
              Class: row.sesis.length + (row.sesis.length > 1 ? ' Classes' : ' Class'),
              Date: minDate === maxDate ? minDate.locale('id').format('DD MMM YYYY') : `${minDate.locale('id').format('DD MMM YYYY')} - ${maxDate.locale('id').format('DD MMM YYYY')}`,
              Topic: row.nama,
              Duration: duration,
              Updated: updatedAt,
              Live: '',
              Assign: '',
              children: dataChildTable,
            }
          })
          // console.log(dataTable)
          commit('SET_STATE', {
            overviewSlot: data.data,
            dataOfTablePlanning: dataTable,
            dataOfTableAssignmentGrading: dataTable,
          })
        })
        .catch(err => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          console.log(err)
        })
    },
    // ! OVERVIEW ASSIGNMENT CENTER GURU
    async FETCH_OVERVIEW_ASSIGNMENT_TEACHER({ commit, dispatch, state }, { idGuru, namaMapel, tingkat }) {
      await axios(`${config.apiUrl}/api/sesi/assignment-overview`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
          nama_mapel: namaMapel,
          tingkat: tingkat,
        },
      })
        .then(({ data }) => {
          const idMapel = data.result.id_mapel
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          // console.log(data.result)
          const dataTable = data.result.class_AP.map(row => {
            const hariIni = moment().format('DD MMM YYYY')
            let students
            let cp
            let avgGrade
            let amountFa = 0
            let amountAip = 0
            let submitted = 0
            let gradedRate = 0
            let graded = 0
            data.result.students.forEach(rowStudents => {
              if (rowStudents[0].id_kelas === row.id) {
                students = rowStudents[0].count
              }
            })
            data.result.cp_fa.forEach(rowCP => {
              if (rowCP.id_kelas === row.id) {
                cp = rowCP.sesis[0].attendances[0].class_presences * 100
                avgGrade = rowCP.sesis[0].attendances[0].fa_avg_grade ? rowCP.sesis[0].attendances[0].fa_avg_grade : '00.00'
                // console.log(rowCP.sesis[0].attendances[0].fa_avg_grade)
              }
            })
            data.result.fa_amount.forEach(rowAmountFa => {
              rowAmountFa.forEach(assignment => {
                if (assignment.id_kelas === row.id) {
                  for (let i = 0; i < assignment.sesis.length; i++) {
                    // console.log(rowAmountFa, 'testes', hariIni > moment(assignment.sesis[i].tugas_deadline2).format('DD MMM YYYY'))
                    if (assignment.sesis[i].tugas_deadline2) {
                      // console.log('ueueueueue', assignment.sesis[i], hariIni > moment(assignment.sesis[i].tugas_deadline2).format('DD MMM YYYY'))
                      if (hariIni > moment(assignment.sesis[i].tugas_deadline2).format('DD MMM YYYY')) {
                        amountFa++
                      } else {
                        // console.log(assignment.sesis[i])
                        amountAip++
                        // avgGrade = '0.00'
                        for (let j = 0; j < assignment.sesis[i].attendances.length; j++) {
                          if (assignment.sesis[i].attendances[j].submit_time) {
                            submitted++
                            if (assignment.sesis[i].attendances[j].tugas_nilai !== null) {
                              graded++
                            }
                          }
                          // console.log(amountAip)
                          gradedRate = (graded / (students * amountAip)) * 100
                        }
                      }
                    }
                  }
                }
              })
            })
            // console.log(avgGrade)
            const newDatas = {
              key: row.id,
              Class: row.tingkat + '-' + row.simbol,
              IdMapel: idMapel,
              Students: students,
              ClassPresences: parseFloat(cp).toFixed(2),
              AmountFinished: amountFa,
              AvgGrade: parseFloat(avgGrade).toFixed(2),
              AmountProgress: amountAip,
              Submitted: submitted,
              Graded: parseFloat(gradedRate).toFixed(2),
            }
            // console.log(newDatas)
            return newDatas
          })
          commit('SET_STATE', {
            overviewAssignment: data.result,
            dataOfTableAP: dataTable,
          })
        })
        .catch(err => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          console.log(err.response)
        })

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.overviewAssignment)
        }, 1000)
      })
    },
    // ! OVERVIEW AIP ASSIGNMENT CENTER GURU
    async FETCH_OVERVIEW_AIP_TEACHER({ commit, dispatch, state }, { idGuru, namaMapel, tingkat }) {
      await axios(`${config.apiUrl}/api/sesi/aip`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
          nama_mapel: namaMapel,
          tingkat: tingkat,
        },
      })
        .then(({ data }) => {
          // console.log(data.data)
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          commit('SET_STATE', {
            overviewAips: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.overviewAips)
        }, 500)
      })
    },
    // ! OVERVIEW AIP DETAIL AAP GURU
    async FETCH_AIP_AAP_TEACHER({ commit, dispatch, state }, { idGuru, idMataPelajaran, idKelas }) {
      await axios(`${config.apiUrl}/api/sesi/aap-aip`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
          id_mata_pelajaran: idMataPelajaran,
          id_kelas: idKelas,
        },
      })
        .then(({ data }) => {
          // console.log(data.data)
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          commit('SET_STATE', {
            aapAips: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.aapAips)
        }, 500)
      })
    },
    // ! OVERVIEW CLASS DETAIL AAP GURU
    async FETCH_OVERVIEW_CLASS_AAP_TEACHER({ commit, dispatch, state }, { idGuru, idMataPelajaran, idKelas }) {
      await axios(`${config.apiUrl}/api/sesi/aap-class`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
          id_mata_pelajaran: idMataPelajaran,
          id_kelas: idKelas,
        },
      })
        .then(({ data }) => {
          let no = 0
          const aColumns = []
          let noSesi = 0
          data.data.assG[0].forEach(rows => {
            rows.sesis.forEach(rowSesi => {
              if (rowSesi.tipe_assignment !== null) {
                noSesi++
                aColumns.push({
                  key: aColumns.length,
                  title: '#' + noSesi,
                  dataIndex: 'A' + rowSesi.id,
                  align: 'center',
                  // width: 10,
                })
              }
            })
          })
          const dataTable = data.data.data_nama_grade[0].kela.murids.map(row => {
            no++
            let cp
            let avgGrade
            data.data.cp_avg.map(rowCp => {
              if (rowCp[0].sesis[0].attendances[0].id_murid === row.id) {
                cp = rowCp[0].sesis[0].attendances[0].class_presences * 100
                avgGrade = rowCp[0].sesis[0].attendances[0].average_grade ? rowCp[0].sesis[0].attendances[0].average_grade : '0.00'
              }
            })
            const coba = {}
            data.data.assG.forEach(rowAsg => {
              // let index = 0
              rowAsg.forEach(rows => {
                rows.sesis.forEach(rowS => {
                  if (rowS.tipe_assignment !== null) {
                    if (rowS.attendances[0].id_murid === row.id) {
                      // index++
                      coba[`A${rowS.id}`] = rowS.attendances[0].tugas_nilai || '-'
                      // console.log(`Column A${index} | ID MURID = ${rowS.attendances[0].id_murid} | SESI = ${rowS.id} | NILAI = ${rowS.attendances[0].tugas_nilai}`)
                    }
                  }
                })
              })
            })
            // console.log(coba)
            // console.log(aColumns)
            return {
              key: row.id,
              No: no,
              Name: row.nama,
              ClassPresences: parseFloat(cp).toFixed(2),
              AverageGrade: parseFloat(avgGrade).toFixed(2),
              ...coba,
            }
          })
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          commit('SET_STATE', {
            overviewClassAipPresence: data.data,
            listAipPresencesMurids: dataTable,
            assignmentColumns: aColumns,
          })
        })
        .catch(err => {
          console.log(err)
        })

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.assignmentColumns)
        }, 500)
      })
    },
    async FETCH_USER_ASSIGNMENT_BY_ID_SESSION({ commit }, { idMurid, idSesi }) {
      let dataAssignment
      await axios(`${config.apiUrl}/api/attendance/assignment-murid`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_murid: idMurid,
          id_sesi: idSesi,
        },
      })
        .then(({ data }) => {
          dataAssignment = data
        })
        .catch(console.log)

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(dataAssignment)
        }, 500)
      })
    },
    async UPDATE_USER_ESSAY_ASSIGNMENT_BY_ID_SESSION({ commit, dispatch }, { idMurid, idSesi, essayAnswer }) {
      await axios(`${config.apiUrl}/api/attendance/assignment-murid`, {
        method: 'put',
        headers: {
          token: localStorage.token,
        },
        data: {
          id_murid: idMurid,
          id_sesi: idSesi,
          essay_answer: essayAnswer,
        },
      })

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(essayAnswer)
        }, 500)
      })
    },
    async FETCH_CLASS_ASSIGNMENT_BY_MAPEL_TEACHER({ commit, dispatch }, { idGuru, idMapel, idWeek, tingkat }) {
      // const { data: { data } } = await axios('http://localhost:3000/api/kelas/class-by-mapel-session', {
      const { data: { data } } = await axios(`${config.apiUrl}/api/kelas/class-by-mapel-session`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
          id_mata_pelajaran: idMapel,
          id_week: idWeek,
          tingkat,
        },
      })

      return new Promise((resolve, reject) => {
        resolve(data)
      })
    },
  },
}
