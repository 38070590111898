<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import config from '@/config'
// import axios from 'axios'
export default {
  created() {
    // axios.defaults.timeout = 2000
    document.title = config.school.name
    if (localStorage.authorized && localStorage.user) {
      this.$store.dispatch('user/GET_USER', { user: JSON.parse(localStorage.user), role: JSON.parse(localStorage.role) })
    }

    // if (this.$workbox) {
    //   this.$workbox.addEventListener('waiting', () => {
    //     this.showUpdateUI = true
    //   })
    // }
  },
}
</script>

<style>
/* *{
  margin: 0;
  padding: 0;
} */

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
