import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    menuSlots: [],
    overviewReport: {},
    detailReport: {},
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_MENU_SLOT_TEACHER({ commit, dispatch, state }, { idGuru }) {
      commit('SET_STATE', {
        isLoading: true,
      }, { root: true })
      await axios(`${config.apiUrl}/api/assesment/tab`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
        },
      })
        .then(({ data }) => {
          commit('SET_STATE', {
            menuSlots: data.data,
          })
        })
        .catch(err => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          console.log(err.response)
        })

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.menuSlots)
        }, 1000)
      })
    },
    async FETCH_OVERVIEW_ASSIGNMENT_TEACHER({ commit, dispatch, state }, { idGuru, idMapel, tingkat }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/assesment/overview`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_mata_pelajaran: idMapel,
            id_guru: idGuru,
            tingkat,
          },
        })

        const overviewReport = data.data

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(overviewReport)
          })
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_DETAIL_RAPORT({ commit, dispatch, state }, { idMapel, idKelas }) {
      let dataTable
      commit('SET_STATE', {
        isLoading: true,
      }, { root: true })
      await axios(`${config.apiUrl}/api/assesment/reports/${idKelas}`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_mata_pelajaran: idMapel,
        },
      })
        .then(({ data }) => {
          dataTable = data.data.detail
          // console.log(dataTable)
          commit('SET_STATE', {
            detailReport: data.data,
          })
        })
        .catch(err => {
          console.log(err.response)
        })

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(dataTable)
        }, 1000)
      })
    },
    async PUT_PH({ commit, dispatch, rootState }, payload) {
      // console.log('masuk', payload.data)
      let isSuccess = false
      let dataTable
      await axios(`${config.apiUrl}/api/assesment/edit-reports`, {
        method: 'put',
        headers: {
          token: localStorage.token,
        },
        data: {
          data: payload.data,
        },
      })
        .then(res => {
          isSuccess = true
          const idMapel = payload.idMapel
          const idKelas = payload.idKelas
          dispatch('FETCH_DETAIL_RAPORT', { idMapel, idKelas })
            .then(res => {
              dataTable = res
              // console.log('data vuex', dataTable)
            })
        })
        .catch(err => {
          isSuccess = false
          console.log(err)
        })

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            isSuccess,
            dataTable,
          })
        }, 2000)
      })
    },
  },
}
