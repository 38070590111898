import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

import router from '@/router'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    header: {},
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_HOMEROOM_HEADER({ commit, dispatch, state }, { idGuru }) {
      let isSuccess = false
      try {
        commit('SET_STATE', {
          isLoading: true,
        }, { root: true })
        const { data } = await axios(`${config.apiUrl}/api/guru/overall-header`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_guru: idGuru,
          },
        })
        commit('SET_STATE', {
          header: data.data,
        })
        isSuccess = true
      } catch (err) {
        const status = err.response.status
        commit('SET_STATE', {
          isLoading: false,
        }, { root: true })
        isSuccess = false
        if (status === 404) {
          router.push({ name: '404' })
        }
      }

      return new Promise((resolve, reject) => {
        resolve(isSuccess)
      })
    },
    FETCH_HOMEROOM_SELECT({ commit, dispatch, state }, { idGuru }) {
      let selectMapel
      let selectWeek
      axios(`${config.apiUrl}/api/guru/mapel-week`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
        },
      })
        .then(({ data }) => {
          selectMapel = data.dataMapel
          selectWeek = data.dataWeek
        })
        .catch(err => {
          console.log(err.response)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            selectMapel,
            selectWeek,
          })
        }, 1000)
      })
    },
    async FETCH_HOMEROOM_TABLE_GRADE({ commit, dispatch, state }, { idGuru, idMataPelajaran }) {
      try {
        let columnMapel
        let rowMurid
        let semester = ''
        if (idMataPelajaran === 'All') {
          idMataPelajaran = ''
        }
        const { data } = await axios(`${config.apiUrl}/api/guru/overall-grade`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_guru: idGuru,
            id_mata_pelajaran: idMataPelajaran,
          },
        })
        console.log(data)
        if (idMataPelajaran === '') {
          columnMapel = data.dataMapel
          rowMurid = data.data
        } else {
          semester = data.semester
          rowMurid = data.data
        }

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({
              columnMapel,
              rowMurid,
              semester,
            })
          }, 1000)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_HOMEROOM_TABLE_PRESENCES({ commit, dispatch, state }, { idGuru, idMataPelajaran }) {
      try {
        let columnTanggal
        let columnMapel
        let rowMurid
        if (idMataPelajaran === 'All') {
          idMataPelajaran = ''
        }
        const { data } = await axios(`${config.apiUrl}/api/guru/overall-presences`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_guru: idGuru,
            id_mata_pelajaran: idMataPelajaran,
          },
        })
        // console.log('VUEX', data)
        if (data.dataTanggalSesi) {
          columnTanggal = data.dataTanggalSesi
          rowMurid = data.presencesMapel
        } else {
          columnMapel = data.dataMapelBaru
          rowMurid = data.dataBaru
        }

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({
              columnMapel,
              columnTanggal,
              rowMurid,
            })
          }, 5000)
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
