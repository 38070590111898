import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_IZIN({ commit }, { page, order, search, sortBy, type }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/izin/table`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
            type,
          },
        })
        const izin = data.data
        return new Promise((resolve, reject) => {
          resolve(izin)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_IZIN({ commit }, payload) {
      let isSuccess = false
      try {
        const { dataIzin } = payload
        await axios(`${config.apiUrl}/api/izin`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataIzin,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async EDIT_IZIN({ commit }, payload) {
      let isSuccess = false
      try {
        const { id, dataIzin } = payload
        await axios(`${config.apiUrl}/api/izin/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataIzin,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_IZIN({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/izin/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_PENGAJUAN_IZIN({ commit }, { page, order, search, sortBy }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/izin/pengajuan_izin`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        const { pengajuanIzin } = data.data

        return new Promise((resolve, reject) => {
          resolve(pengajuanIzin)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async APPROVE_PENGAJUAN_IZIN({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/izin/pengajuan_izin/terima/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async REJECT_PENGAJUAN_IZIN({ commit }, { id, dataPengajuanIzin }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/izin/pengajuan_izin/tolak/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataPengajuanIzin,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
  },
}
