import Vue from 'vue'
import VueRouter from 'vue-router'
// import MainLayout from '@/layouts/Main'
// import store from '@/store'
// import Login from '@/views/Auth/Login'
// import ForgotPassword from '@/views/Auth/ForgotPassword'
// import Dashboard from '@/views/Teacher/Dashboard'
// import AccountTeacher from '@/views/Teacher/Account'
// import AccountStudent from '@/views/Student/Account'
// import DashboardStudents from '@/views/Student/DashboardStudents'
// Start Admin Routes
// import AccountAdmin from '@/views/Admin/Account'
// import DashboardAdmin from '@/views/Admin/Dashboard'
// import TeachersAdmin from '@/views/Admin/Teachers'
// import AddTeachersAdmin from '@/views/Admin/Teachers/Add'
// import DetailTeacherAdmin from '@/views/Admin/Teachers/Detail'
// import ScheduleTeachersAdmin from '@/views/Admin/Teachers/Schedule'
// import StudentsAdmin from '@/views/Admin/Students'
// import DetailStudentAdmin from '@/views/Admin/Students/Detail'
// import AddStudentAdmin from '@/views/Admin/Students/Add'
// import ClassesAdmin from '@/views/Admin/Classes'
// import DetailClassAdmin from '@/views/Admin/Classes/Detail'
// End Admin Routes
// import DetailSchedule from '@/views/Teacher/DetailSchedule'
// import Planning from '@/views/Teacher/Planning'
// import Schedule from '@/views/Teacher/Schedule'
// import Assignments from '@/views/Teacher/Assignments'
// import GradingAssignments from '@/views/Teacher/Assignments/Grading'
// import PresencesAssignments from '@/views/Teacher/Assignments/Presences'
// import EditSchedule from '@/views/EditSchedule'
// import Reports from '@/views/Teacher/Reports'
// import DetailReport from '@/views/Teacher/Reports/Detail'
// import CbtTeacher from '@/views/Teacher/Cbt'
// import AddCbtTeacher from '@/views/Teacher/Cbt/Add'
// import DetailCbtTeacher from '@/views/Teacher/Cbt/Detail'
// import ResultCbtTeacher from '@/views/Teacher/Cbt/Result'
// import HomeroomTeacher from '@/views/Teacher/Homeroom'
// import DetailHomeroomTeacher from '@/views/Teacher/Homeroom/Detail'
// import CbtStudent from '@/views/Student/CbtStudent'
// import ClassStudent from '@/views/Student/ClassStudent'
// import ScheduleStudent from '@/views/Student/ScheduleStudent'
// import DetailScheduleStudent from '@/views/Student/DetailSchedule'
// import DetailSubjectStudent from '@/views/Student/DetailSubject'
// import ExamCBTList from '@/views/Student/ExamCBTList'
// import StartExamCBT from '@/views/Student/ExamCBTList/StartExamCBT'

// TRY NEW IMPORT FOR IMPROVING PERFORMANCE

const mainLayout = () => import(/* webpackChunkName: "mainLayout" */'../layouts/Main')

const login = () => import(/* webpackChunkName: "login" */'../views/Auth/Login')
const forgotPassword = () => import(/* webpackChunkName: "forgotPassword" */'../views/Auth/ForgotPassword')

// TEACHER
const accountTeacher = () => import(/* webpackChunkName: "accountTeacher" */'../views/Teacher/Account')
const dashboardTeacher = () => import(/* webpackChunkName: "dashboardTeacher" */'../views/Teacher/Dashboard')

const scheduleTeacher = () => import(/* webpackChunkName: "scheduleTeacher" */'../views/Teacher/Schedule')
const detailScheduleTeacher = () => import(/* webpackChunkName: "scheduleTeacher" */'../views/Teacher/DetailSchedule')
const editScheduleTeacher = () => import(/* webpackChunkName: "scheduleTeacher" */'../views/EditSchedule')

const planningTeacher = () => import(/* webpackChunkName: "planningTeacher" */'../views/Teacher/Planning')

const assignmentTeacher = () => import(/* webpackChunkName: "assignmentTeacher" */'../views/Teacher/Assignments')
const gradingAssignmentTeacher = () => import(/* webpackChunkName: "gradingAssignmentTeacher" */'../views/Teacher/Assignments/Grading')
const presencesAssignmentTeacher = () => import(/* webpackChunkName: "presencesAssignmentTeacher" */'../views/Teacher/Assignments/Presences')

const reportsTeacher = () => import(/* webpackChunkName: "reportsTeacher" */'../views/Teacher/Reports')
const detailReportsTeacher = () => import(/* webpackChunkName: "detailReportsTeacher" */'../views/Teacher/Reports/Detail')

const cbtTeacher = () => import(/* webpackChunkName: "cbtTeacher" */'../views/Teacher/Cbt')
const mySuperVisionCbtTeacher = () => import('../views/Teacher/Cbt/MySupervision')
const addCbtTeacher = () => import(/* webpackChunkName: "addCbtTeacher" */'../views/Teacher/Cbt/Add')
const detailCbtTeacher = () => import(/* webpackChunkName: "detailCbtTeacher" */'../views/Teacher/Cbt/Detail')
const resultCbtTeacher = () => import(/* webpackChunkName: "resultCbtTeacher" */'../views/Teacher/Cbt/Result')

const homeroomTeacher = () => import(/* webpackChunkName: "homeroomTeacher" */'../views/Teacher/Homeroom')
const detailHomeroomTeacher = () => import(/* webpackChunkName: "detailHomeroomTeacher" */'../views/Teacher/Homeroom/Detail')
// TEACHER

// STUDENT
const accountStudent = () => import(/* webpackChunkName: "accountStudent" */'../views/Student/Account')
const dashboardStudent = () => import(/* webpackChunkName: "dashboardStudent" */'../views/Student/DashboardStudents')

const scheduleStudent = () => import(/* webpackChunkName: "scheduleStudent" */'../views/Student/ScheduleStudent')
const detailScheduleStudent = () => import(/* webpackChunkName: "detailScheduleStudent" */'../views/Student/DetailSchedule')

const classStudent = () => import(/* webpackChunkName: "classStudent" */'../views/Student/ClassStudent')
const detailSubjectStudent = () => import(/* webpackChunkName: "detailSubjectStudent" */'../views/Student/DetailSubject')

const examCbtStudent = () => import(/* webpackChunkName: "examCbtStudent" */'../views/Student/ExamCBTList')
const startCbtStudent = () => import(/* webpackChunkName: "startCbtStudent" */'../views/Student/ExamCBTList/StartExamCBT')
// STUDENT

// ADMIN
const accountAdmin = () => import(/* webpackChunkName: "accountAdmin" */'../views/Admin/Account')
const dashboardAdmin = () => import(/* webpackChunkName: "dashboardAdmin" */'../views/Admin/Dashboard')

const teachersAdmin = () => import(/* webpackChunkName: "teachersAdmin" */'../views/Admin/Teachers')
const addTeachersAdmin = () => import(/* webpackChunkName: "addTeachersAdmin" */'../views/Admin/Teachers/Add')
const detailTeachersAdmin = () => import(/* webpackChunkName: "detailTeachersAdmin" */'../views/Admin/Teachers/Detail')
const scheduleTeachersAdmin = () => import(/* webpackChunkName: "scheduleTeachersAdmin" */'../views/Admin/Teachers/Schedule')

const studentsAdmin = () => import(/* webpackChunkName: "studentsAdmin" */'../views/Admin/Students')
const addStudentsAdmin = () => import(/* webpackChunkName: "addStudentsAdmin" */'../views/Admin/Students/Add')
const detailStudentsAdmin = () => import(/* webpackChunkName: "detailStudentsAdmin" */'../views/Admin/Students/Detail')

const classesAdmin = () => import(/* webpackChunkName: "classesAdmin" */'../views/Admin/Classes')
const detailClassesAdmin = () => import(/* webpackChunkName: "detailClassesAdmin" */'../views/Admin/Classes/Detail')
// ADMIN

// ADMIN HR
const DashboardAdminHR = () => import(/* webpackChunkName: "dashboardAdminHR" */'../views/AdminHR/Dashboard')
const KehadiranAdminHR = () => import(/* webpackChunkName: "kehadiranAdminHR" */'../views/AdminHR/Attendance')
const MasterDataAdminHR = () => import(/* webpackChunkName: "masterAdminHR" */'../views/AdminHR/MasterData')
const detailRekapAbsenAdminHR = () => import(/* webpackChunkName: "detailRekapAbsenAdminHR" */'../views/AdminHR/Attendance/RekapitulasiKehadiran/Detail')
// ADMIN HR

// ADMIN KEUANGAN
const DashboardAdminKeuangan = () => import(/* webpackChunkName: "dashboardAdminHR" */'../views/AdminKeuangan/Dashboard')
const KeuanganAdminKeuangan = () => import(/* webpackChunkName: "keuanganAdminHR" */'../views/AdminKeuangan/Finance')
const MasterDataAdminKeuangan = () => import(/* webpackChunkName: "masterAdminHR" */'../views/AdminKeuangan/MasterData')
// ADMIN KEUANGAN

// PEGAWAI
const dasborPegawai = () => import(/* webpackChunkName: "dasborPegawai" */'../views/Pegawai/Dasbor')
const keuanganPegawai = () => import(/* webpackChunkName: "dasborPegawai" */'../views/Pegawai/Keuangan')

const SuccessVerify = () => import(/* webpackChunkName: "successVerify" */'../views/Onboarding/SuccessVerify')
const MyAttendance = () => import(/* webpackChunkName: "myAttendance" */'../views/Attendance/MyAttendance')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/teacher',
    component: mainLayout,
    redirect: { name: 'Dashboard Teacher' },
    meta: {
      authRequired: true,
      hidden: true,
      role: ['guruMapel'],
    },
    children: [{
      path: 'dashboard',
      name: 'Dashboard Teacher',
      component: dashboardTeacher,
    }, {
    //   path: 'my-attendance',
    //   name: 'My Attendance',
    //   component: MyAttendance,
    // }, {
      path: 'success-verify',
      name: 'Success Verify Teacher',
      component: SuccessVerify,
    }, {
      path: 'account',
      name: 'Account Teacher',
      component: accountTeacher,
    }, {
      path: 'schedule',
      name: 'Schedule Teacher',
      component: scheduleTeacher,
    }, {
      path: 'planning',
      name: 'Planning Teacher',
      component: planningTeacher,
    }, {
      path: 'assignments',
      name: 'Assignments Teacher',
      component: assignmentTeacher,
    }, {
      path: 'assignments/:id/grading',
      name: 'Grading Assignments Teacher',
      component: gradingAssignmentTeacher,
    }, {
      path: 'assignments/:id/:idMapel/presences',
      name: 'Presences Assignments Teacher',
      component: presencesAssignmentTeacher,
    }, {
      path: 'schedule/:id',
      name: 'Detail Schedule Teacher',
      component: detailScheduleTeacher,
    }, {
      path: '/schedule/:id/edit',
      name: 'Edit Schedule',
      component: editScheduleTeacher,
    }, {
      path: 'reports',
      name: 'Reports Teacher',
      component: reportsTeacher,
    }, {
      path: 'reports/:idMapel/:idKelas/detail',
      name: 'Detail Report Teacher',
      component: detailReportsTeacher,
    }, {
      path: 'cbt',
      name: 'Cbt Teacher',
      component: cbtTeacher,
    }, {
      path: 'cbt/my-supervision/:id',
      name: 'Cbt Supervision Teacher',
      component: mySuperVisionCbtTeacher,
    }, {
      path: 'cbt/add',
      name: 'Add Cbt Teacher',
      component: addCbtTeacher,
    }, {
      path: 'cbt/detail/:id',
      name: 'Detail Cbt Teacher',
      component: detailCbtTeacher,
    }, {
      path: 'cbt/result/:id',
      name: 'Result Cbt Teacher',
      component: resultCbtTeacher,
    }, {
      path: 'homeroom',
      name: 'Homeroom Teacher',
      component: homeroomTeacher,
    }, {
      path: 'homeroom/:id',
      name: 'Detail Homeroom Teacher',
      component: detailHomeroomTeacher,
    }],
  },
  {
    path: '/admin-hr',
    component: mainLayout,
    redirect: { name: 'Dasbor Admin HR' },
    meta: {
      authRequired: true,
      hidden: true,
      role: ['HR'],
    },
    children: [{
      path: 'dasbor',
      name: 'Dasbor Admin HR',
      component: DashboardAdminHR,
    }, {
      path: 'kehadiran',
      name: 'Kehadiran Admin HR',
      component: KehadiranAdminHR,
    }, {
      path: 'kehadiran/detail/:idPegawai/:idPeriode',
      name: 'Detail Rekap Absen',
      component: detailRekapAbsenAdminHR,
    }, {
      path: 'master-data',
      name: 'Master Data Admin HR',
      component: MasterDataAdminHR,
    }],
  },
  {
    path: '/admin-keuangan',
    component: mainLayout,
    redirect: { name: 'Dasbor Admin Keuangan' },
    meta: {
      authRequired: true,
      hidden: true,
      role: ['keuangan'],
    },
    children: [{
      path: 'dasbor',
      name: 'Dasbor Admin Keuangan',
      component: DashboardAdminKeuangan,
    }, {
      path: 'keuangan',
      name: 'Keuangan Admin Keuangan',
      component: KeuanganAdminKeuangan,
    }, {
      path: 'master-data',
      name: 'Master Data Admin Keuangan',
      component: MasterDataAdminKeuangan,
    }],
  },
  {
    path: '/pegawai',
    component: mainLayout,
    redirect: { name: 'Dasbor Pegawai' },
    meta: {
      authRequired: true,
      hidden: true,
      role: ['guruMapel', 'pegawai'],
    },
    children: [{
      path: 'dasbor',
      name: 'Dasbor Pegawai',
      component: dasborPegawai,
    }, {
      path: 'keuangan',
      name: 'Keuangan Pegawai',
      component: keuanganPegawai,
    }, {
      path: 'my-attendance',
      name: 'My Attendance',
      component: MyAttendance,
    }],
  },
  {
    path: '/admin',
    component: mainLayout,
    redirect: { name: 'Dashboard Admin' },
    meta: {
      authRequired: true,
      hidden: true,
      role: ['admin', 'guruAdmin'],
    },
    children: [{
      path: 'dashboard',
      name: 'Dashboard Admin',
      component: dashboardAdmin,
    }, {
      path: 'teacher',
      name: 'Teacher Admin',
      component: teachersAdmin,
    }, {
      path: 'teacher/add',
      name: 'Add Teacher Admin',
      component: addTeachersAdmin,
    }, {
      path: 'teacher/detail/:id',
      name: 'Detail Teacher Admin',
      component: detailTeachersAdmin,
    }, {
      path: 'teacher/schedule/:id',
      name: 'Schedule Teacher Admin',
      component: scheduleTeachersAdmin,
    }, {
      path: 'student',
      name: 'Student Admin',
      component: studentsAdmin,
    }, {
      path: 'student/detail/:id',
      name: 'Detail Student Admin',
      component: detailStudentsAdmin,
    }, {
      path: 'account',
      name: 'Account Admin',
      component: accountAdmin,
    }, {
      path: 'students/add',
      name: 'Add Student Admin',
      component: addStudentsAdmin,
    }, {
      path: 'class',
      name: 'Class Admin',
      component: classesAdmin,
    }, {
      path: 'class/detail/:id',
      name: 'Detail Class Admin',
      component: detailClassesAdmin,
    }],
  },
  {
    path: '/student',
    component: mainLayout,
    redirect: { name: 'Dashboard Student' },
    meta: {
      authRequired: true,
      hidden: true,
      role: ['murid'],
    },
    children: [{
      path: 'dashboard',
      name: 'Dashboard Student',
      component: dashboardStudent,
    }, {
      path: 'success-verify',
      name: 'Success Verify Student',
      component: SuccessVerify,
    }, {
      path: 'account',
      name: 'Account Student',
      component: accountStudent,
    }, {
      path: 'schedule',
      name: 'Schedule Student',
      component: scheduleStudent,
    }, {
      path: 'class',
      name: 'Class Student',
      component: classStudent,
    }, {
      path: 'cbt',
      name: 'Cbt Student',
      component: examCbtStudent,
    }, {
      path: 'schedule/:id',
      name: 'Detail Schedule Student',
      component: detailScheduleStudent,
    }, {
      path: 'class/:idSubject',
      name: 'Detail Subject Student',
      component: detailSubjectStudent,
    }, {
      path: 'cbt/:id/exam',
      name: 'Cbt Start Exam Student',
      component: startCbtStudent,
    }],
  },
  {
    path: '/login',
    name: 'Login',
    component: login,
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: forgotPassword,
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/Auth/404'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '*',
    redirect: { name: '404' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const isOnSEB = navigator.userAgent.indexOf('SEB') !== -1
  // const isOnSEB = true
  if (to.name !== 'Cbt Start Exam Student' && to.matched.some(record => record.meta.authRequired)) {
    // console.log(to)
    localStorage.setItem('isOnExam', false)
  }

  // kondisi 1 : ngecek meta authRequired url yang dituju
  if (to.matched.some(record => record.meta.authRequired)) {
    // ngecek apakah sudah login atau belum
    if (!localStorage.authorized) {
      next({
        path: '/login',
      })
    } else {
      if (to.path === '/') { // apabila menuju '/' dan sudah login
        if (localStorage.role.includes('guruMapel')) {
          next({
            name: 'Dashboard Teacher',
          })
        } else if (localStorage.role.includes('murid')) {
          next({
            name: 'Dashboard Student',
          })
        } else if (localStorage.role.includes('admin')) {
          next({
            name: 'Dashboard Admin',
          })
        } else if (localStorage.role.includes('HR')) {
          next({
            name: 'Kehadiran Admin HR',
          })
        } else if (localStorage.role.includes('keuangan')) {
          next({
            name: 'Keuangan Admin Keuangan',
          })
        } else if (localStorage.role.includes('pegawai')) {
          next({
            name: 'Dasbor Pegawai',
          })
        } else {
          next()
        }
      } else {
        if (to.matched.some(record => record.meta.role &&
          !record.meta.role.some(role => JSON.parse(localStorage.role).includes(role)))
        ) { // supaya ga bisa buka route dari role lain
          next({
            name: '404',
          })
        } else {
          if (to.name === 'Cbt Start Exam Student' && !isOnSEB) {
            next({
              name: '404',
            })
          } else {
            next()
          }
        }
      }
    }
  } else {
    if (!to.meta.authRequired && localStorage.authorized) { // misal : buka login padahal sudah login dan belum logout
      if (localStorage.role.includes('guruMapel')) {
        next({
          name: 'Dashboard Teacher',
        })
      } else if (localStorage.role.includes('murid')) {
        next({
          name: 'Dashboard Student',
        })
      } else if (localStorage.role.includes('admin')) {
        next({
          name: 'Dashboard Admin',
        })
      } else if (localStorage.role.includes('HR')) {
        next({
          name: 'Kehadiran Admin HR',
        })
      } else if (localStorage.role.includes('keuangan')) {
        next({
          name: 'Keuangan Admin Keuangan',
        })
      } else if (localStorage.role.includes('pegawai')) {
        next({
          name: 'Dasbor Pegawai',
        })
      } else {
        next()
      }
    } else if (to.path === '/') {
      next({
        path: '/login',
      })
    } else {
      next()
    }
  }
})

export default router
