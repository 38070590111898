import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'
import router from '@/router'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    isOnExam: false,
    mapelClasses: {},
    status: '',
    listCBTMurid: [],
    dataQuestionCBTStudent: {},
    totalCbtMurid: 0,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_MAPEL_BY_ID_TEACHER({ commit, dispatch, state }, { idGuru }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/cbt/mapel-kelas`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            id_guru: idGuru,
          },
        })

        const mapel = data.data
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(mapel)
          }, 500)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_ALL_CBT_ATTENDANCES_BY_ID_CBT({ commit, dispatch, state }, { idCbt }) {
      const { data } = await axios(`${config.apiUrl}/api/cbt/all`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        params: {
          id: idCbt,
        },
      })

      return new Promise((resolve, reject) => {
        resolve(data)
      })
    },
    async FETCH_CBT_TEACHER({ commit, dispatch, state }, { idGuru, page, order, tipe, search, sortBy }) {
      // console.log(tipe)
      const { data } = await axios(`${config.apiUrl}/api/cbt`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
          page,
          order,
          tipe,
          search,
          sortBy,
        },
      })
      const cbts = data.data
      const total = data.totalCBT
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ cbts, total })
        }, 500)
      })
    },
    async FETCH_CBT_SCHEDULE_TEACHER({ commit, dispatch, state }, { idGuru, page, order, tipe, search, sortBy }) {
      // console.log(tipe)
      const { data } = await axios(`${config.apiUrl}/api/cbt/supervision`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
          page,
          order,
          tipe,
          search,
          sortBy,
        },
      })
      const cbts = data.data
      const total = data.totalCBT
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ cbts, total })
        }, 1000)
      })
    },
    async FETCH_PENGAWAS({ commit, dispatch, state }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/guru`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })

        const teachers = data.data
        // console.log(teachers)
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(teachers)
          }, 500)
        })
      } catch (error) {
        console.log(error)
      }
    },
    FETCH_CBT_BY_ID({ commit, dispatch, state }, { idCbt, idGuru }) {
      let cbt
      let isAttendanceSubmitted
      let listPengawas
      axios(`${config.apiUrl}/api/cbt/${idCbt}`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
        },
      })
        .then(({ data }) => {
          // console.log(data.cbt)
          cbt = data.cbt
          isAttendanceSubmitted = data.isAttendanceSubmitted
          listPengawas = data.listPengawas
          // commit('SET_STATE', {
          //   mapelClasses: data.data,
          // })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            cbt,
            isAttendanceSubmitted,
            listPengawas,
          })
        }, 1000)
      })
    },
    async FETCH_CBT_SCHEDULE_BY_ID({ commit, dispatch, state }, { idCbt, idGuru }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/cbt/supervision/detail-head`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            id_guru: idGuru,
            id_cbt: idCbt,
          },
        })
        const cbt = data.data
        // console.log(cbt)
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({
              cbt,
            })
          }, 1000)
        })
      } catch (error) {
        console.log(error)
      }
    },
    FETCH_CBT_SLOT({ commit, dispatch, state }, { idCbt, idGuru }) {
      let message
      axios(`${config.apiUrl}/api/cbt/guru/mapel-check/${idCbt}`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
        },
      })
        .then(({ data }) => {
          message = data.message
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(message)
        }, 1000)
      })
    },
    FETCH_SUPERVISION({ commit, dispatch, state }) {
      let res
      axios(`${config.apiUrl}/api/cbt/supervision/get-schedule`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then(({ data }) => {
          res = data
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(res)
        }, 1000)
      })
    },
    REMOVE_SUPERVISION({ commit, dispatch, state }) {
      let message
      axios(`${config.apiUrl}/api/cbt/supervision/unlink-schedule`, {
        method: 'put',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then(res => {
          message = res.data.message
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(message)
        }, 1000)
      })
    },
    DELETE_CBT({ commit, dispatch, state }, { idCbt }) {
      let message
      axios(`${config.apiUrl}/api/cbt/${idCbt}`, {
        method: 'delete',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then(res => {
          message = res.data.message
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(message)
        }, 1000)
      })
    },
    async FETCH_CBT_ATTENDANCES_BY_ID({ commit, dispatch, state }, { idCbt, page, order, sortBy, idKelas }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/cbt/guru/grading-cbt/${idCbt}`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            id_kelas: idKelas,
            page,
            order,
            sortBy,
          },
        })
        // .then(({ data }) => {
        // console.log(JSON.parse(data.data.cbt.cbt_attendances[0].jawaban))
        const cbt = data.data
        // })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(cbt)
          }, 1000)
        })
      } catch (err) {
        console.log(err)
      }
    },
    POST_CBT({ commit, dispatch, state }, { idGuru, dataCBT, cbtSoalBundle, pengawas }) {
      // console.log('CBT DATA', dataCBT)
      // console.log('SOAL DATA', cbtSoalBundle)
      let status = ''
      axios(`${config.apiUrl}/api/cbt/generate`, {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        data: {
          id_guru: idGuru,
          ...dataCBT,
          cbtSoalBundle,
          pengawas,
        },
      })
        .then(({ data }) => {
          status = data.message
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 2000)
      })
    },
    PUT_CBT({ commit, dispatch, state }, { idCbt, idGuru, dataCBT, pengawas }) {
      // console.log('CBT DATA', dataCBT)
      // console.log('SOAL DATA', cbtSoalBundle)
      let status = ''
      axios(`${config.apiUrl}/api/cbt/edit-cbt`, {
        method: 'put',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        data: {
          id_cbt: idCbt,
          id_guru: idGuru,
          ...dataCBT,
          pengawas,
        },
      })
        .then(({ data }) => {
          status = data.message
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 2000)
      })
    },
    PUT_CBT_SOAL({ commit, dispatch, state }, { idCbt, idGuru, cbtSoalBundle }) {
      let status = ''
      axios(`${config.apiUrl}/api/cbt/edit-cbtSoal`, {
        method: 'put',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        data: {
          id_cbt: idCbt,
          id_guru: idGuru,
          cbtSoalBundle,
        },
      })
        .then(({ data }) => {
          status = data.message
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 2000)
      })
    },
    APPROVE_CBT({ commit, dispatch, state }, { idCbt, idGuru }) {
      // console.log('CBT DATA', dataCBT)
      // console.log('SOAL DATA', cbtSoalBundle)
      let status = ''
      axios(`${config.apiUrl}/api/cbt/approval`, {
        method: 'put',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        data: {
          id_cbt: idCbt,
          id_guru: idGuru,
        },
      })
        .then(({ data }) => {
          status = data.message
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 2000)
      })
    },
    PUT_ESSAY_ANSWER({ commit, dispatch, state }, { id, idGuru, nilaiBundle }) {
      let status
      // console.log('VUEX', id, nilaiBundle)
      axios(`${config.apiUrl}/api/cbt/guru/grading-essay/${id}`, {
        method: 'put',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        data: {
          id_guru: idGuru,
          nilaiBundle,
        },
      })
        .then(({ data }) => {
          status = data
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 1000)
      })
    },
    async FETCH_CBT_MURID_LIST({ commit }, { idMurid, searchCBT, order, page, sortBy, tipe }) {
      try {
        const { data: { data, status, total } } = await axios(config.apiUrl + '/api/cbt/murid', {
          method: 'get',
          params: {
            id_murid: idMurid,
            search: searchCBT,
            order,
            page,
            sortBy,
            tipe,
          },
          headers: {
            token: localStorage.token,
          },
        })

        commit('SET_STATE', {
          listCBTMurid: data,
          status: status,
          totalCbtMurid: total,
        })

        return new Promise((resolve, reject) => {
          resolve(total)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_BRIEF_PAGE_STUDENT_CBT_EXAM({ commit }, { idMurid, idCBT }) {
      try {
        const { data: { data } } = await axios(config.apiUrl + '/api/cbt/murid/brief-page', {
          method: 'get',
          params: {
            id_murid: idMurid,
            id_cbt: idCBT,
          },
          headers: {
            token: localStorage.token,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        if (err.response.data.status === 'NOT_AUTHORIZED') {
          router.push({ name: '404' })
        }
        console.log(err)
      }
    },
    async FETCH_STUDENT_CBT_QUESTION({ commit }, { idMurid, idCBT }) {
      try {
        const { data: { data } } = await axios(config.apiUrl + '/api/cbt/murid/exam-page', {
          method: 'get',
          params: {
            id_murid: idMurid,
            id_cbt: idCBT,
          },
          headers: {
            token: localStorage.token,
          },
        })
        // console.log(data.soalRandom)
        data.exam.cbt_soals = data.soalRandom
        commit('SET_STATE', {
          dataQuestionCBTStudent: data.exam,
          isOnExam: true,
        })
        localStorage.setItem('isOnExam', true)
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        if (err.response.data.status === 'NOT_AUTHORIZED') {
          router.push({ name: '404' })
          localStorage.setItem('isOnExam', false)
          commit('SET_STATE', {
            isOnExam: false,
          })
        }
        console.log(err)
      }
    },
    async UPDATE_JAWABAN_MURID_CBT_EXAM({ commit }, { jawaban, idCbtAttendance }) {
      try {
        await axios(config.apiUrl + '/api/cbt/murid/do-exam/' + idCbtAttendance, {
          method: 'put',
          data: {
            jawaban,
          },
          headers: {
            token: localStorage.token,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(true)
        })
        // console.log('suksesss')
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
    },
    async SUBMIT_ANSWER({ commit }, { idCbtAttendance }) {
      try {
        await axios(config.apiUrl + '/api/cbt/murid/finish-exam/' + idCbtAttendance, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } catch (err) {
        console.log(err)

        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
    },
    async UPDATE_START_TIME({ commit }, { idCbtAttendance }) {
      try {
        await axios(config.apiUrl + '/api/cbt/murid/start-time/' + idCbtAttendance, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(false)
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      }
    },
    async CHECK_CBT_ATTENDACE({ commit }, { idMurid, idCbt }) {
      try {
        const { data: { data } } = await axios(config.apiUrl + '/api/cbt/murid/cbt-check-status', {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_murid: idMurid,
            id_cbt: idCbt,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_TABLE_SUPERVISION({ commit }, { idPengawas, idCbt }) {
      try {
        const { data } = await axios(config.apiUrl + '/api/cbt/supervision/detail-body', {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_guru: idPengawas,
            id_cbt: idCbt,
          },
        })

        return new Promise((resolve, reject) => {
          resolve({
            ...data.dataCBT,
            hitungCBTsoal: data.hitungCBTsoal,
          })
        })
      } catch (err) {
        console.log(err)
      }
    },
    async UPDATE_NOTES_BERITA_ACARA({ commit }, { idGuru, idCbt, notesBeritaAcara }) {
      try {
        const { data } = await axios(config.apiUrl + '/api/cbt/supervision/update-notes-berita-acara', {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            notes_berita_acara: notesBeritaAcara,
          },
          params: {
            id_guru: idGuru,
            id_cbt: idCbt,
          },
        })

        return new Promise((resolve, reject) => {
          resolve({
            ...data.dataCBT,
            hitungCBTsoal: data.hitungCBTsoal,
          })
        })
      } catch (err) {
        console.log(err)
      }
    },
  },
}
