import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    allIsAttend: false,
    newestAssignmentResults: [],
    assignmentInProgresses: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    FETCH_ASSIGNMENT_IN_PROGRESS({ commit, dispatch }, { idMurid }) {
      axios(`${config.apiUrl}/api/dashboard-murid/aip`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_murid: idMurid,
        },
      })
        .then(({ data }) => {
          // console.log(data.data.rows)
          commit('SET_STATE', {
            assignmentInProgresses: data.data.rows,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    FETCH_NEWEST_ASSIGNMENT_RESULT({ commit, dispatch }, { idMurid }) {
      axios(`${config.apiUrl}/api/dashboard-murid/newest`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_murid: idMurid,
        },
      })
        .then(({ data: { data } }) => {
          commit('SET_STATE', {
            newestAssignmentResults: data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    async FETCH_ALL_ATTENDANCE({ commit, dispatch }, { idSession, idMapel, idKelas }) {
      let allAttendance = []
      await axios(`${config.apiUrl}/api/attendance/by-session`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_sesi: idSession,
          id_mata_pelajaran: idMapel,
          id_kelas: idKelas,
        },
      })
        .then(({ data }) => {
          if (data.data) {
            allAttendance = data.data.attendances
            const { totalSesiMapel, attendanceCount } = data.data
            attendanceCount.forEach(murid => {
              allAttendance.forEach(muridAttendanceBySesi => {
                if (murid.id === muridAttendanceBySesi.id_murid) {
                  muridAttendanceBySesi.percentageKehadiran = (murid.attendances.length / totalSesiMapel) * 100
                }
              })
            })
          }
        })
        .catch(err => {
          console.log(err.response)
        })

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(allAttendance)
        }, 1000)
      })
    },
    async FETCH_ATTENDANCE_BY_ID_MURID({ commit, dispatch }, { idMurid, idSession }) {
      let dataAttendanceMurid = {}
      commit('SET_STATE', {
        isLoading: true,
      }, { root: true })
      await axios(`${config.apiUrl}/api/attendance/assignment-murid`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_murid: idMurid,
          id_sesi: idSession,
        },
      })
        .then(({ data }) => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          dataAttendanceMurid = data
        })
        .catch(err => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          console.log(err)
        })

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(dataAttendanceMurid)
        }, 1000)
      })
    },
    UPDATE_SINGLE_ATTENDANCE({ commit, dispatch }, { idAttendance, status }) {
      // console.log(idAttendance, status)
      axios(`${config.apiUrl}/api/attendance/single-attend/${idAttendance}`, {
        method: 'put',
        headers: {
          token: localStorage.token,
        },
        data: {
          status_attendance: status,
        },
      })
        .then(_ => {
        })
        .catch(console.log)
    },
    async UPDATE_ALL_ATTENDANCE_BY_SESSION({ commit, dispatch }, { idSesi }) {
      let isSuccess = false
      await axios(`${config.apiUrl}/api/attendance/all-attend`, {
        method: 'put',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_sesi: idSesi,
        },
      })
        .then(_ => {
          isSuccess = false
        })
        .catch(console.log)

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(isSuccess)
        }, 1000)
      })
    },
    async SAVE_NOTES_BY_ID_ATTENDANCE({ commit, dispatch }, { id, notes }) {
      let isSuccess = false
      await axios(`${config.apiUrl}/api/attendance/update-notes/${id}`, {
        method: 'put',
        headers: {
          token: localStorage.token,
        },
        data: {
          notes,
        },
      })
        .then(_ => {
          isSuccess = true
        })
        .catch(console.log)

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(isSuccess)
        }, 1000)
      })
    },
  },
}
