/* eslint-disable */

import { register } from 'register-service-worker'
import axios from 'axios'
import config from './config.js'
const publicVapidKey = 'BGY8_VRq8rIKwtoAAxaKqo84PzyU3X7X6FMuF2VENJU4oQOLgnnYNAu0Lr5RSOfaas7aAJQLfZIY8XtZpoyXduE'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB',
      )

      Notification.requestPermission(status => {
        console.log(status, 'status')
      })
    },
    async registered(registration) {
      console.log('Service worker has been registered.', registration)
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: publicVapidKey,
      })

      await axios.post(`${config.apiUrl}/api/notification/`, {
        // data: JSON.stringify(subscription),
        data: subscription,
        headers: {
          'content-type': 'application/json',
        },
      })
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated() {
      console.log('New content is available; please refresh.')
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.',
      )
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}
