import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    teachers: [],
    teacher: {},
    students: [],
    student: {},
    classes: [],
    class: {},
    mapels: [],
    waliKelas: [],
    tahunAjar: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async UPDATE_STATUS_TAHUN_AJARAN({ commit, dispatch, state }, { id }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/tahun_ajaran/${id}`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(data.flag)
          }, 500)
        })
      } catch (error) {
        console.log(error)
      }
    },

    FETCH_TEACHERS({ commit, dispatch, state }, { page, order, search }) {
      axios(`${config.apiUrl}/api/admin/all-guru`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        params: {
          order,
          search,
          page,
        },
      })
        .then(({ data }) => {
          commit('SET_STATE', {
            teachers: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.teachers)
        }, 500)
      })
    },
    FETCH_TEACHERS_BY_ID({ commit, dispatch, state }, { id }) {
      axios(`${config.apiUrl}/api/admin/guru/${id}`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then(({ data }) => {
          commit('SET_STATE', {
            teacher: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.teacher)
        }, 500)
      })
    },
    ADD_TEACHER({ commit, dispatch, state }, { data }) {
      let status
      // console.log(data)
      axios(`${config.apiUrl}/api/admin/guru`, {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        data: {
          nik: data.nik,
          nama: data.nama,
          jenis_kelamin: data.jenis_kelamin,
          password: data.nik,
          role: '["guruMapel"]',
        },
      })
        .then((data) => {
          status = data.status
        })
        .catch(err => {
          status = err.response.data.message
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 500)
      })
    },
    UPDATE_TEACHER({ commit, dispatch, state }, { data }) {
      let status
      axios(`${config.apiUrl}/api/admin/guru/${data.id}`, {
        method: 'put',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        data: {
          nik: data.NIK,
          nama: data.nama,
          jenis_kelamin: data.jenis_kelamin,
          email: data.email,
          alamat: data.alamat,
        },
      })
        .then((data) => {
          status = data.status
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 500)
      })
    },
    DELETE_TEACHER({ commit, dispatch, state }, { id }) {
      let status
      axios(`${config.apiUrl}/api/guru/${id}`, {
        method: 'delete',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then((data) => {
          status = data.status
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 500)
      })
    },
    UPDATE_TEACHER_PASSWORD({ commit, dispatch, state }, { id, data }) {
      let status
      axios(`${config.apiUrl}/api/admin/guru/change_password/${id}`, {
        method: 'put',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        data: {
          password: data,
        },
      })
        .then((data) => {
          status = data.status
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 500)
      })
    },
    FETCH_CLASSES({ commit, dispatch, state }, { page, order, search }) {
      axios(`${config.apiUrl}/api/admin/all-kelas`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        params: {
          order,
          search,
          page,
        },
      })
        .then(({ data }) => {
          commit('SET_STATE', {
            classes: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.classes)
        }, 500)
      })
    },
    async FETCH_CLASS({ commit, dispatch, state }) {
      const { data } = await axios(`${config.apiUrl}/api/kelas`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
      // console.log(data.data)
      commit('SET_STATE', {
        classes: data.data,
      })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.classes)
        }, 500)
      })
    },
    FETCH_CLASS_BY_ID({ commit, dispatch, state }, { id }) {
      axios(`${config.apiUrl}/api/admin/kelas/${id}`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then(({ data }) => {
          // console.log(data.kelas.data)
          commit('SET_STATE', {
            class: data,
          })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.class)
        }, 500)
      })
    },
    FETCH_WALI_KELAS({ commit, dispatch, state }) {
      axios(`${config.apiUrl}/api/admin/kelas/guru-available`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then(({ data }) => {
          // console.log(data)
          commit('SET_STATE', {
            waliKelas: data.available,
          })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.waliKelas)
        }, 500)
      })
    },
    FETCH_TAHUN_AJARAN({ commit, dispatch, state }) {
      axios(`${config.apiUrl}/api/tahun_ajaran`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then(({ data }) => {
          // console.log(data.data)
          commit('SET_STATE', {
            tahunAjar: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.tahunAjar)
        }, 500)
      })
    },
    UPDATE_CLASS({ commit, dispatch, state }, { data }) {
      let status
      axios(`${config.apiUrl}/api/admin/kelas/${data.id}`, {
        method: 'put',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        data: {
          id_guru: data.id_guru,
          id_tahun_ajaran: data.id_tahun_ajaran,
        },
      })
        .then((data) => {
          status = data.status
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 500)
      })
    },
    FETCH_MAPEL({ commit, dispatch, state }) {
      axios(`${config.apiUrl}/api/mata_pelajaran`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then(({ data }) => {
          // console.log(data.data)
          commit('SET_STATE', {
            mapels: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.mapels)
        }, 500)
      })
    },
    FETCH_STUDENTS({ commit, dispatch, state }, { page, kelasId, order, search }) {
      axios(`${config.apiUrl}/api/admin/all-murid`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        params: {
          order,
          search,
          page,
          id_kelas: kelasId,
        },
      })
        .then(({ data }) => {
          commit('SET_STATE', {
            students: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.students)
        }, 500)
      })
    },
    FETCH_STUDENTS_BY_ID({ commit, dispatch, state }, { id }) {
      axios(`${config.apiUrl}/api/admin/murid/${id}`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then(({ data }) => {
          commit('SET_STATE', {
            student: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.student)
        }, 500)
      })
    },
    ADD_STUDENT({ commit, dispatch, state }, { data }) {
      let status
      // console.log(data)
      axios(`${config.apiUrl}/api/admin/murid`, {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        data: {
          id_kelas: data.id_kelas,
          tahun_masuk: data.tahun_masuk,
          nis: data.nis,
          nama: data.nama,
          jenis_kelamin: data.jenis_kelamin,
          password: data.nis,
        },
      })
        .then((data) => {
          status = data.status
        })
        .catch(err => {
          status = err.response.data.message
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 500)
      })
    },
    UPDATE_STUDENT({ commit, dispatch, state }, { data }) {
      let status
      axios(`${config.apiUrl}/api/admin/murid/${data.id}`, {
        method: 'put',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        data: {
          nis: data.nis,
          nama: data.nama,
          jenis_kelamin: data.jenis_kelamin,
          email: data.email,
          alamat: data.alamat,
        },
      })
        .then((data) => {
          status = data.status
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 500)
      })
    },
    UPDATE_STUDENT_PASSWORD({ commit, dispatch, state }, { id, data }) {
      let status
      axios(`${config.apiUrl}/api/admin/murid/change_password/${id}`, {
        method: 'put',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        data: {
          password: data,
        },
      })
        .then((data) => {
          status = data.status
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 500)
      })
    },
    DELETE_STUDENT({ commit, dispatch, state }, { id }) {
      let status
      axios(`${config.apiUrl}/api/murid/${id}`, {
        method: 'delete',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then((data) => {
          status = data.status
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 500)
      })
    },
    async FETCH_SCHEDULE_TEACHER({ commit, dispatch, state, rootState }, { idGuru }) {
      const { data: { data: { nama: namaGuru } } } = await axios(`${config.apiUrl}/api/guru/${idGuru}`, {
        headers: {
          token: localStorage.token,
        },
      })
      const { data: { data: allSlot } } = await axios(`${config.apiUrl}/api/admin/schedule-teacher`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
          tipe: rootState.slot.modelTipe,
        },
      })

      // console.log(namaGuru, allSlot)
      return new Promise((resolve, reject) => {
        resolve({ namaGuru, allSlot })
      })
    },
    async FETCH_CLASS_AND_SUBJECT_BY_SLOT({ commit, dispatch, state }, { idGuru, posisi, hari }) {
      try {
        let kelas = []
        let mapel = []
        const { data: { data } } = await axios(`${config.apiUrl}/api/kelas/class-by-slot`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            id_guru: idGuru,
            posisi,
            hari,
          },
        })
        kelas = data.kelas
        mapel = data.mapel
        // commit('SET_STATE', {
        //   classes: data.data,
        // })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({ kelas, mapel })
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async CREATE_SLOT({ commit, dispatch, state }, { slot }) {
      try {
        await axios(`${config.apiUrl}/api/slot`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            slot,
          },
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        console.log(err)
      }
    },
    async GET_TEACHER_BY_ID_MAPEL({ commit, dispatch, state }, { idMapel }) {
      try {
        // console.log(idMapel)
        const { data: { data: guru } } = await axios(`${config.apiUrl}/api/admin/teacher-by-id-mapel`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            id_mata_pelajaran: idMapel,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(guru)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async UPDATE_SLOT({ commit, dispatch, state }, { idSlot, slot }) {
      try {
        await axios(`${config.apiUrl}/api/admin/update-slot/${idSlot}`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            slot,
          },
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        console.log(err)
      }
    },
    async DELETE_SLOT({ commit, dispatch, state }, { idSlot }) {
      try {
        await axios(`${config.apiUrl}/api/slot/hard_delete/${idSlot}`, {
          method: 'delete',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })

        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        console.log(err)
      }
    },
    async VALIDATION_CLASS_SLOT({ commit, dispatch, state }, { tipeModelJadwal, jamMulai, idKelas, posisi, hari }) {
      try {
        // console.log(tipeModelJadwal, jamMulai, posisi, hari, idKelas)
        const { data: { data } } = await axios(`${config.apiUrl}/api/admin/validation/class-and-slot`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            tipe_model_jadwal: tipeModelJadwal,
            jam_mulai: jamMulai,
            id_kelas: idKelas,
            posisi,
            hari,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(data.isValidate)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async VALIDATE_EDIT_SLOT({ commit, dispatch, state }, { idGuru, tipeModelJadwal, jamMulai, idKelas, posisi, hari, durasi, jamPelajaran }) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/admin/validation/edit-slot`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            id_guru: idGuru,
            durasi,
            tipe_model_jadwal: tipeModelJadwal,
            jam_mulai: jamMulai,
            id_kelas: idKelas,
            posisi,
            hari,
            jam_pelajaran: jamPelajaran,
          },
        })

        // console.log(data)

        return new Promise((resolve, reject) => {
          resolve(data.isValidate)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async EDIT_PAYMENT_STATUS({ commit, dispatch, state }, { status, idMurid }) {
      try {
        await axios(`${config.apiUrl}/api/admin/status/murid/${idMurid}`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            status,
          },
        })

        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        console.log(err)
      }
    },
  },
}
