import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_SHIFT({ commit }, { page, order, search, sortBy }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/shift`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        const shifts = data.data

        return new Promise((resolve, reject) => {
          resolve(shifts)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_SHIFT({ commit }, payload) {
      let isSuccess = false
      try {
        const { dataShift, dataJadwal } = payload
        await axios(`${config.apiUrl}/api/shift/create`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            dataShift,
            dataJadwal,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async EDIT_SHIFT({ commit }, payload) {
      let isSuccess = false
      try {
        const { id, dataShift, dataJadwal } = payload
        await axios(`${config.apiUrl}/api/shift/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            dataShift,
            dataJadwal,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_SHIFT({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/shift/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_SHIFT_EMPLOYEE({ commit }, { id, page, order, search, sortBy }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/shift/shift_pegawai/${id}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        const pegawai = data.data.shiftPegawai
        const total = data.data.total

        return new Promise((resolve, reject) => {
          resolve({ pegawai, total })
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_SHIFT_EMPLOYEE_SELECTED({ commit }, { id, dataPegawai }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/shift/create_shift_pegawai/${id}`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            dataPegawai,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_SHIFT_EMPLOYEE_SELECTED({ commit }, { dataPegawai }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/shift/remove_shift_pegawai`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            dataPegawai,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_ALL_EMPLOYEE({ commit }, { page, order, search, sortBy }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/shift/all_pegawai`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        const pegawai = data.data.pegawai
        const total = data.data.total

        return new Promise((resolve, reject) => {
          resolve({ pegawai, total })
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_SHIFT_PEGAWAI({ commit }, { idPegawai }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/shift/list_shift_pegawai_by_id_pegawai/${idPegawai}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        const shiftPegawai = data.data

        return new Promise((resolve, reject) => {
          resolve(shiftPegawai)
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
