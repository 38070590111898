import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async GET_GOLONGAN_BY_SITUATION({ commit, dispatch, state }, { order, sortBy, search, page }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/golongan/situation`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            order,
            sortBy,
            search,
            page,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async UPDATE_GOLONGAN({ commit, dispatch, state }, { data }) {
      try {
        let isSuccess = false
        await axios(`${config.apiUrl}/api/golongan/bulk-update`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            data,
          },
        })
        return new Promise((resolve, reject) => {
          isSuccess = true
          resolve({
            isSuccess,
            dataTable: data,
          })
        })
      } catch (err) {
        console.log(err)
      }
    },
    async GET_JABATAN_BY_SITUATION({ commit, dispatch, state }, { order, sortBy, search, page }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/jabatan/situation`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            order,
            sortBy,
            search,
            page,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async UPDATE_JABATAN({ commit, dispatch, state }, { data }) {
      try {
        let isSuccess = false
        await axios(`${config.apiUrl}/api/jabatan/finance/bulk-update`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            data,
          },
        })
        return new Promise((resolve, reject) => {
          isSuccess = true
          resolve({
            isSuccess,
            dataTable: data,
          })
        })
      } catch (err) {
        console.log(err)
      }
    },
    async GET_MARITAL_BY_SITUATION({ commit, dispatch, state }, { order, sortBy, search, page }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/marital_tunjangan/situation`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            order,
            sortBy,
            search,
            page,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async UPDATE_MARITAL({ commit, dispatch, state }, { data, role }) {
      try {
        let isSuccess = false
        await axios(`${config.apiUrl}/api/marital_tunjangan/bulk-update`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            data,
          },
        })
        return new Promise((resolve, reject) => {
          isSuccess = true
          resolve({
            isSuccess,
            dataTable: data,
          })
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_ALL_PAYROLL_RECAP_BY_PERIOD({ commit, dispatch, state }, { order, sortBy, search, page, idPeriod, unit }) {
      try {
        const { data: { data, total, periode } } = await axios(`${config.apiUrl}/api/rincian-gaji/`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: { order, sortBy, search, page, id_periode: idPeriod, unit },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({ data, total, periode })
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async UPDATE_RINCIAN_GAJI_TO_FINALISASI({ commit, dispatch, state }, { id }) {
      try {
        let isSuccess = false
        await axios(`${config.apiUrl}/api/rincian-gaji/finalisasi/${id}`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          isSuccess = true
          resolve({
            isSuccess,
          })
        })
      } catch (err) {
        console.log(err)
      }
    },
    async SYNC_RINCIAN_GAJI_RECAP({ commit, dispatch, state }, { idPeriod }) {
      try {
        await axios(`${config.apiUrl}/api/rincian-gaji/sync`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: { id_periode: idPeriod },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(true)
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_DETAIL_PENCATATAN_GAJI({ commit, dispatch, state }, { idRincianGaji, idPeriod }) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/rincian-gaji/${idRincianGaji}`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: { id_periode: idPeriod },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(data)
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async GET_INSENTIF_BY_SITUATION({ commit, dispatch, state }, { order, sortBy, search, page }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/insentif/situation`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            order,
            sortBy,
            search,
            page,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async GET_INSENTIF_PEGAWAI_BY_ID_INSENTIF({ commit, dispatch, state }, { id }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/insentif/pegawai/${id}`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async UPDATE_INSENTIF_PEGAWAI({ commit, dispatch, state }, { data, id }) {
      try {
        let isSuccess = false
        await axios(`${config.apiUrl}/api/insentif/pegawai/${id}`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            data,
          },
        })
        return new Promise((resolve, reject) => {
          isSuccess = true
          resolve({
            isSuccess,
          })
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FINALISASI_INSENTIF({ commit, dispatch, state }, { id }) {
      try {
        await axios(`${config.apiUrl}/api/insentif/finalisasi/${id}`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } catch (err) {
        console.log(err)
      }
    },
  },
}
