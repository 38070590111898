import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'
import router from '@/router'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    subjects: {},
    currentSubject: {},
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_SUBJECTS_STUDENT({ commit, dispatch }, { idMurid, idKelas }) {
      commit('SET_STATE', {
        isLoading: true,
      }, { root: true })
      await axios(`${config.apiUrl}/api/kelas/class-by-subject`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_murid: idMurid,
          id_kelas: idKelas,
        },
      })
        .then(({ data: { data } }) => {
          // console.log(data)
          setTimeout(() => {
            commit('SET_STATE', {
              isLoading: false,
            }, { root: true })
          }, 1000)
          commit('SET_STATE', {
            subjects: data,
          })
        })
        .catch(console.log)

      return new Promise((resolve, reject) => {
        resolve()
      })
    },
    async FETCH_SUMMARY_ASSIGNMENT({ commit, dispatch }, { idMapel, idMurid }) {
      try {
        commit('SET_STATE', { isLoading: true }, { root: true })
        const { data } = await axios(`${config.apiUrl}/api/kelas/by-subject/assignment-summary`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_murid: idMurid,
            id_mata_pelajaran: idMapel,
          },
        })
        commit('SET_STATE', { isLoading: false }, { root: true })
        const summary = data.data

        return new Promise((resolve, reject) => {
          resolve(summary)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_SUMMARY_ASSIGNMENT_ALL_SUBJECT({ commit, dispatch }, { idMurid }) {
      try {
        // commit('SET_STATE', { isLoading: true }, { root: true })
        const { data } = await axios(`${config.apiUrl}/api/kelas/assignment-summary`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_murid: idMurid,
          },
        })
        // commit('SET_STATE', { isLoading: false }, { root: true })
        const summary = data.data

        return new Promise((resolve, reject) => {
          resolve(summary)
        })
      } catch (error) {
        console.log(error)
      }
    },
    FETCH_CARD_AIP({ commit, dispatch, state }, { idMapel, idMurid, idKelas }) {
      commit('SET_STATE', {
        isLoading: true,
      }, { root: true })
      axios(`${config.apiUrl}/api/sesi/class-by-subject/detail/aip-card`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_mata_pelajaran: idMapel,
          id_murid: idMurid,
          id_kelas: idKelas,
        },
      })
        .then(({ data: { data } }) => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          // console.log(data, 'ini aip card')
          // const sessions = data.aipCard.map(sesi => {
          //   return {
          //     namaMapel: sesi.mata_pelajaran.nama,

          //   }
          // })
          commit('SET_STATE', {
            currentSubject: {
              ...state.currentSubject,
              AIPCard: data.aipCard,
              totalWeeks: data.totalWeek,
            },
          })
        })

        .catch(err => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          if (err.response.data.status === 'NOT_AUTHORIZED') {
            router.push({ name: '404' })
          }
          console.log(err.response)
        })
    },
    FETCH_LIST_AIP({ commit, dispatch, state }, { idMapel, idMurid, idKelas }) {
      commit('SET_STATE', {
        isLoading: false,
      }, { root: true })
      axios(`${config.apiUrl}/api/sesi/class-by-subject/detail/aip-list`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_mata_pelajaran: idMapel,
          id_murid: idMurid,
          id_kelas: idKelas,
        },
      })
        .then(({ data: { data } }) => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          commit('SET_STATE', {
            currentSubject: {
              ...state.currentSubject,
              AIPList: data,
            },
          })
        })

        .catch(err => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          if (err.response.data.status === 'NOT_AUTHORIZED') {
            router.push({ name: '404' })
          }
          console.log(err.response)
        })
    },
    FETCH_STUDENT_OVERALL({ commit, dispatch, state }, { idMapel, idMurid, idKelas }) {
      commit('SET_STATE', {
        isLoading: true,
      }, { root: true })
      axios(`${config.apiUrl}/api/slot/student-overall-by-subject`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_mata_pelajaran: idMapel,
          id_murid: idMurid,
          id_kelas: idKelas,
        },
      })
        .then(({ data: { data } }) => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          commit('SET_STATE', {
            currentSubject: {
              ...state.currentSubject,
              studentOverall: data,
            },
          })
        })

        .catch(err => {
          commit('SET_STATE', {
            isLoading: false,
          }, { root: true })
          if (err.response.data.status === 'NOT_AUTHORIZED') {
            router.push({ name: '404' })
          }
          console.log(err.response)
        })
    },
  },
}
