import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async CREATE_UNIT({ commit, dispatch, state }, { data }) {
      try {
        await axios(`${config.apiUrl}/api/unit/create`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            nama: data.nama,
          },
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        console.log(err)
      }
    },
    async GET_UNIT_BY_SITUATION({ commit, dispatch, state }, { order, sortBy, search, page }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/unit/situation`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            order,
            sortBy,
            search,
            page,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async UPDATE_UNIT({ commit, dispatch, state }, { data }) {
      try {
        let isSuccess = false
        await axios(`${config.apiUrl}/api/unit/bulk-update`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            data,
          },
        })
        return new Promise((resolve, reject) => {
          isSuccess = true
          resolve({
            isSuccess,
            dataTable: data,
          })
        })
      } catch (err) {
        console.log(err)
      }
    },
    async DELETE_UNIT({ commit, dispatch, state }, { id }) {
      try {
        let status
        await axios(`${config.apiUrl}/api/unit/${id}`, {
          method: 'delete',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
          .then((data) => {
            status = data.status
          })
          .catch(err => {
            console.log(err)
          })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(status)
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async CREATE_GOLONGAN({ commit, dispatch, state }, { data }) {
      try {
        await axios(`${config.apiUrl}/api/golongan/create`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            nama: data.nama,
            gaji_pokok: data.gapok,
          },
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        console.log(err)
      }
    },
    async GET_GOLONGAN_BY_SITUATION({ commit, dispatch, state }, { order, sortBy, search, page }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/golongan/situation`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            order,
            sortBy,
            search,
            page,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async UPDATE_GOLONGAN({ commit, dispatch, state }, { data }) {
      try {
        let isSuccess = false
        await axios(`${config.apiUrl}/api/golongan/bulk-update`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            data,
          },
        })
        return new Promise((resolve, reject) => {
          isSuccess = true
          resolve({
            isSuccess,
            dataTable: data,
          })
        })
      } catch (err) {
        console.log(err)
      }
    },
    async DELETE_GOLONGAN({ commit, dispatch, state }, { id }) {
      try {
        let status
        await axios(`${config.apiUrl}/api/golongan/${id}`, {
          method: 'delete',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
          .then((data) => {
            status = data.status
          })
          .catch(err => {
            console.log(err)
          })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(status)
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async CREATE_JABATAN({ commit, dispatch, state }, { data }) {
      try {
        let status
        await axios(`${config.apiUrl}/api/jabatan/create`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data,
        })
          .then((data) => {
            status = data.status
          })
          .catch(err => {
            console.log(err)
          })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(status)
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async GET_JABATAN_BY_SITUATION({ commit, dispatch, state }, { order, sortBy, search, page }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/jabatan/situation`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            order,
            sortBy,
            search,
            page,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async UPDATE_JABATAN({ commit, dispatch, state }, { data }) {
      try {
        let isSuccess = false
        await axios(`${config.apiUrl}/api/jabatan/hr/bulk-update`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            data,
          },
        })
        return new Promise((resolve, reject) => {
          isSuccess = true
          resolve({
            isSuccess,
            dataTable: data,
          })
        })
      } catch (err) {
        console.log(err)
      }
    },
    async DELETE_JABATAN({ commit, dispatch, state }, { id }) {
      try {
        let status
        await axios(`${config.apiUrl}/api/jabatan/${id}`, {
          method: 'delete',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
          .then((data) => {
            status = data.status
          })
          .catch(err => {
            console.log(err)
          })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(status)
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async CREATE_MARITAL({ commit, dispatch, state }, { data }) {
      try {
        let status
        await axios(`${config.apiUrl}/api/marital_tunjangan/create`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data,
        })
          .then((data) => {
            status = data.status
          })
          .catch(err => {
            console.log(err)
          })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(status)
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async GET_MARITAL_BY_SITUATION({ commit, dispatch, state }, { order, sortBy, search, page }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/marital_tunjangan/situation`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            order,
            sortBy,
            search,
            page,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async UPDATE_MARITAL({ commit, dispatch, state }, { data, role }) {
      try {
        let isSuccess = false
        await axios(`${config.apiUrl}/api/marital_tunjangan/bulk-update`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            data,
          },
        })
        return new Promise((resolve, reject) => {
          isSuccess = true
          resolve({
            isSuccess,
            dataTable: data,
          })
        })
      } catch (err) {
        console.log(err)
      }
    },
    async DELETE_MARITAL({ commit, dispatch, state }, { id }) {
      try {
        let status
        await axios(`${config.apiUrl}/api/marital_tunjangan/${id}`, {
          method: 'delete',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
          .then((data) => {
            status = data.status
          })
          .catch(err => {
            console.log(err)
          })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(status)
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async CREATE_PEGAWAI({ commit, dispatch, state }, { data }) {
      try {
        let dataCreated
        await axios(`${config.apiUrl}/api/pegawai/create`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            namaPegawai: data.namaPegawai,
            nir: data.nir,
            idGolongan: data.idGolongan,
            idUnit: data.idUnit,
            jabatanBundle: data.jabatanBundle,
            idMarital: data.idMarital,
            statusPegawai: data.statusPegawai,
            tanggalBergabung: data.tanggalBergabung,
            jenisKelamin: data.jenisKelamin,
          },
        })
          .then((data) => {
            dataCreated = data
          })
          .catch(err => {
            console.log(err)
          })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(dataCreated)
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async GET_PEGAWAI_BY_SITUATION({ commit, dispatch, state }, { order, sortBy, search, page, selectedUnitId, filterBy, filterParams }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/pegawai/situation`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            order,
            sortBy,
            search,
            page,
            selectedUnitId,
            filterBy,
            filterParams,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async GET_PEGAWAI_BY_ID({ commit, dispatch, state }, { id }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/pegawai/get-by/${id}`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async UPDATE_PEGAWAI({ commit, dispatch, state }, { data, id }) {
      try {
        let isSuccess = false
        await axios(`${config.apiUrl}/api/pegawai/update/${id}`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            data,
          },
        })
        return new Promise((resolve, reject) => {
          isSuccess = true
          resolve({
            isSuccess,
            data,
          })
        })
      } catch (err) {
        console.log(err)
      }
    },
    async DELETE_PEGAWAI({ commit, dispatch, state }, { id }) {
      try {
        let status
        await axios(`${config.apiUrl}/api/pegawai/${id}`, {
          method: 'delete',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
          .then((data) => {
            status = data.status
          })
          .catch(err => {
            console.log(err)
          })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(status)
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_ALL_PENGAJUAN_LEMBUR({ commit, dispatch, state }, { order, sortBy, search, page }) {
      try {
        const { data: { data, total } } = await axios(`${config.apiUrl}/api/pengajuan-lembur/`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({ data, total })
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_ALL_PRESENCE_RECAP({ commit, dispatch, state }, { order, sortBy, search, page, idPeriod, unit }) {
      try {
        const { data: { data, total, periode } } = await axios(`${config.apiUrl}/api/rekap-absen-hr/by-period`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            order,
            sortBy,
            search,
            page,
            id_periode: idPeriod,
            unit,
          },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({ data, total, periode })
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_ALL_ABSENT_LOG({ commit, dispatch, state }, { order, sortBy, search, page, idPeriod, unit, dateLog }) {
      try {
        const { data: { data, total } } = await axios(`${config.apiUrl}/api/absensi-hr/log`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            order,
            sortBy,
            search,
            page,
            id_periode: idPeriod,
            unit,
            dateLog,
          },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({ data, total })
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async EDIT_PENGAJUAN_PEGAWAI({ commit, dispatch, state }, { id, dataLembur }) {
      try {
        await axios(`${config.apiUrl}/api/pengajuan-lembur/admin-hr`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: dataLembur,
          params: { id },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(true)
          }, 500)
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(false)
          }, 500)
        })
      }
    },
    async FINALIZE_ABSENT_RECAP({ commit, dispatch, state }, { idPeriod }) {
      try {
        await axios(`${config.apiUrl}/api/periode-hr/pengecekan`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: { id_periode: idPeriod },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(true)
          }, 500)
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(false)
          }, 500)
        })
      }
    },
    async UPDATE_PAYMENT_STATUS({ commit, dispatch, state }, { id }) {
      try {
        await axios(`${config.apiUrl}/api/rincian-gaji/status/${id}`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(true)
          }, 500)
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(false)
          }, 500)
        })
      }
    },
  },
}
