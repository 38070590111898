import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_PERIODE_KEHADIRAN({ commit }, { page, order, search, sortBy }) {
      // console.log(axios.defaults)
      try {
        const { data } = await axios(`${config.apiUrl}/api/periode-hr`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        const periode = data.data

        return new Promise((resolve, reject) => {
          resolve(periode)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_PERIODE_KEHADIRAN({ commit }, payload) {
      let isSuccess = false
      try {
        const { dataPeriode } = payload
        await axios(`${config.apiUrl}/api/periode-hr`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataPeriode,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async EDIT_PERIODE_KEHADIRAN({ commit }, payload) {
      let isSuccess = false
      try {
        const { id, dataPeriode } = payload
        await axios(`${config.apiUrl}/api/periode-hr/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataPeriode,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_PERIODE_KEHADIRAN({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/periode-hr/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_ALL_UNIT({ commit }) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/unit/`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
    },
    async SYNC_ABSENT_RECAP({ commit }, { idPeriod }) {
      try {
        await axios(`${config.apiUrl}/api/rekap-absen-hr/sync`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          params: { id_periode: idPeriod },
          // timeout: 2000,
        })

        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
    },
    async FETCH_TODAY_ABSENT({ commit }) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/absensi-hr/pegawai/today`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
    },
    async FETCH_ALL_CUTI({ commit }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/cuti`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })

        const cuti = data.data

        return new Promise((resolve, reject) => {
          resolve(cuti)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async UPDATE_ABSENT_EMPLOYEE({ commit }, { statusAbsensi, image, position, ip, os, browser, device }) {
      try {
        await axios(`${config.apiUrl}/api/absensi-hr/pegawai`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: { status_absensi: statusAbsensi, image, position, detail_perangkat: { ip, os, browser, device } },
        })

        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } catch (error) {
        // console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async FETCH_PENGAJUAN_CUTI({ commit }, { idPegawai, page, order, search, sortBy }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/cuti/pengajuan_cuti_by_pegawai/${idPegawai}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        const { pengajuanCuti } = data.data

        return new Promise((resolve, reject) => {
          resolve(pengajuanCuti)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async DELETE_PENGAJUAN_CUTI_PEGAWAI({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/cuti/pengajuan_cuti_by_pegawai/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })
        isSuccess = true
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_ALL_IZIN({ commit }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/izin`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        const izin = data.data

        return new Promise((resolve, reject) => {
          resolve(izin)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_PENGAJUAN_IZIN({ commit }, { idPegawai, page, order, search, sortBy }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/izin/pengajuan_izin_by_pegawai/${idPegawai}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        const { pengajuanIzin } = data.data

        return new Promise((resolve, reject) => {
          resolve(pengajuanIzin)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async DELETE_PENGAJUAN_IZIN_PEGAWAI({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/izin/pengajuan_izin_by_pegawai/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })
        isSuccess = true
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_PENGAJUAN_LEMBUR({ commit }, { idPegawai, page, order, search, sortBy }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/pengajuan-lembur/pengajuan_lembur_by_pegawai/${idPegawai}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        const { pengajuanLembur } = data.data

        return new Promise((resolve, reject) => {
          resolve(pengajuanLembur)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async DELETE_PENGAJUAN_LEMBUR_PEGAWAI({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/pengajuan-lembur/pengajuan_lembur_by_pegawai/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })
        isSuccess = true
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_PERIODE_PEGAWAI({ commit }, { idPegawai, idPeriode }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/absensi-hr/periode-pegawai`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            idPegawai,
            idPeriode,
          },
        })
        const result = data.data
        return new Promise((resolve, reject) => {
          resolve(result)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_DAILY_ATTENDANCE({ commit }, { idPegawai, idPeriode, selectDate }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/absensi-hr/absensi-harian`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            idPegawai,
            idPeriode,
            selectDate,
          },
        })
        const result = data.data
        return new Promise((resolve, reject) => {
          resolve(result)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_ABSENSI_FROM_ADMIN_HR({ commit }, { data }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/absensi-hr/adminHR`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            data,
          },
        })
        isSuccess = true
        return new Promise((resolve, reject) => {
          resolve({ isSuccess })
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_ABSENSI({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/absensi-hr/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })
        isSuccess = true
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async UPDATE_ABSENSI({ commit }, { id, data }) {
      try {
        await axios(`${config.apiUrl}/api/absensi-hr/adminHR/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: { data },
        })

        return new Promise((resolve, reject) => {
          resolve({ isSuccess: true })
        })
      } catch (error) {
        // console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async FETCH_STATISTIK_DASHBOARD({ commit }, { idPegawai }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/periode-hr/statistik_dashboard_pegawai/${idPegawai}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        const result = data.data
        return new Promise((resolve, reject) => {
          resolve(result)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_PERSENTASE_KEHADIRAN_BULANAN({ commit }, { idPegawai }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/rekap-absen-hr/percentage_present_by_month/${idPegawai}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        const result = data.data
        return new Promise((resolve, reject) => {
          resolve(result)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_ATTENDANCE_LOG({ commit }, { idPegawai, startDate, endDate }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/pegawai/log-kehadiran`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_pegawai: idPegawai,
            startDate,
            endDate,
          },
        })
        const result = data.data
        return new Promise((resolve, reject) => {
          resolve(result)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_RINCIAN_GAJI_BY_ID({ commit }, { idPegawai, page, order, sortBy, year }) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/rincian-gaji/pegawai/${idPegawai}`, {
          method: 'get',
          headers: { token: localStorage.token },
          params: { page, order, sortBy, tahun: year },
        })

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        // console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
  },
}
