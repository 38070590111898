import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    signature: '',
    isLive: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  action: {
    ZOOM_MEETINGS({ commit }) {
      axios(`${config.apiUrl}/api/create-room`, {
        method: 'post',
        headers: {
          token: localStorage.token,
        },
        data: {
          meetingNumber: 1,
          role: 0,
        },
      })
        .then(data => {
        })
    },
  },
}
