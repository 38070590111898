import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_INSENTIF({ commit }, { page, order, search, sortBy }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/insentif`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        const insentif = data.data

        return new Promise((resolve, reject) => {
          resolve(insentif)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_INSENTIF({ commit }, payload) {
      let isSuccess = false
      try {
        const { dataInsentif } = payload
        await axios(`${config.apiUrl}/api/insentif`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataInsentif,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async EDIT_INSENTIF({ commit }, payload) {
      let isSuccess = false
      try {
        const { id, dataInsentif } = payload
        await axios(`${config.apiUrl}/api/insentif/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataInsentif,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_INSENTIF({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/insentif/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_INSENTIF_EMPLOYEE({ commit }, { id, page, order, search, sortBy }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/insentif/insentif_pegawai/${id}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        const { insentifPegawai, total } = data.data

        return new Promise((resolve, reject) => {
          resolve({ insentifPegawai, total })
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_INSENTIF_EMPLOYEE_SELECTED({ commit }, { id, dataPegawai }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/insentif/insentif_pegawai/add/${id}`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            dataPegawai,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_INSENTIF_EMPLOYEE_SELECTED({ commit }, { dataPegawai }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/insentif/insentif_pegawai/remove`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            dataPegawai,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async PUBLISH_INSENTIF_HR({ commit }, { id, dataInsentif }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/insentif/publish_hr/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataInsentif,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_ALL_EMPLOYEE({ commit }, { page, order, search, sortBy }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/insentif/insentif_pegawai/all_pegawai`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        const { pegawai, total } = data.data

        return new Promise((resolve, reject) => {
          resolve({ pegawai, total })
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
